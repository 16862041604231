import { Http } from '@zhfe/common-library';
import { Injectable } from '@tanbo/vue-di-plugin'
import { Subject } from 'rxjs'
import { BridgeService } from './bridge.service';
import { CookieService } from './cookie.service';
import { ConfigService } from '@/services/config.service';
import { CommonService } from './common.service';
import axios from 'axios'

/** 通用的可牛会员类型 */
export enum KENIU_VIP_TYPE {
  /** 可牛普通 */
  normal = 'keniu_normal',
  /** 可牛钻石 */
  diamond = 'keniu_diamond'
}

export interface WxInfo {
  /** 用户的对应wx公众号 open_id(仅微信用户返回) */
  wx_web_open_id: string
}
export interface UserInfo {
  /** 用户的uid */
  open_id: string
  /** 用户唯一标识 */
  token: string
  /** 登录类型（0:未知 1:微信 2:QQ 3:手机） */
  login_type: number
  /** 头像url */
  avatar?: string
  /** 用户名 */
  nickname?: string
  /** 微信信息 */
  wx_info?: WxInfo
}

export interface PageDetail {
  /** 图标链接 */
  img_url?: string
  /** 是否永久会员 */
  is_diamond?: string
  /** 名字 */
  name?: string
}

export interface VipInfoList {
  /** vip的类型 */
  vip_type: string
  /** 有效期 */
  expired_at: string
  /** 是否签约购买(TODO: 要考虑下如何获取) */
  is_continuous: boolean
  /** 展示的相关信息 */
  page_detail: PageDetail
  /** 可牛看图- 智能抠图兼容次数展示字段 */
  fastpic_cutout_free?: number
  fastpic_cutout_independent?: number
}

export interface PermissionList {
  /** 权益类型 */
  permission_type: string
  /** 有效期 */
  expired_at: number
  /** 功能剩余次数(仅当为次数权益有效) */
  left_cnt: number
}

export interface UserInfoResponseData {
  user_info: UserInfo
  /** 用户有效的权益信息列表 */
  permission_list?: PermissionList[]
  /** vip信息（有vip才会买） */
  vip_info_list?: VipInfoList[]
}

export interface CommentDetail {
  avatar: string,
  user_name: string,
  comment_detail: string,
  comment_time: string
} 

export interface CommentInfoResponseData {
  comments: CommentDetail,
  has_more: boolean
}

export interface longUserInfoParams {
  /** 用户的唯一令牌 */
  open_id: string
  /** 用户唯一标识 */
  token: string
  userInfo?: UserInfoResponseData
}

/** 可牛看图-智能抠图权益类别 */
export enum permissionType {
  // 赠送抠图权益
  give= 'fastpic_cutout_free',
  // 独立抠图权益
  stand= 'fastpic_cutout_independent'
}

/** 用户评论AB测 */
export enum commentABTestEnum {
  /** A测 */
  ATest = 'aTest',
  /** B测 */
  BTest = 'bTest',
  /** C测 */
  CTest = 'cTest',
}

@Injectable()
export class UserService {
  onUserInfoChanged = new Subject<void>() // 用户信息改变事件（登录，支付 等触发场景）
  onLogout = new Subject<void>() // 退出改变事件（退出登录 等触发场景）

  scrollSub = new Subject<void>() // 滚动事件（滚动页面，请求用户评论数据）

  userInfo = {} as UserInfoResponseData;

  constructor(
    private http: Http,
    private bridge: BridgeService,
    private cookie: CookieService,
    private config: ConfigService,
    private common: CommonService
  ) {
    if (this.isLogin) {
      this.requestUserInfo().then(() => {
        this.updateBridgeNeedUserParams()
      })
    }

    this.onLogoutSubscribe()
  }

  /** 是否登录 */
  get isLogin(): boolean {
    return this.cookie.isLogin
  }

  /** 用户登录类型 */
  get loignType(): number {
    return this.userInfo?.user_info?.login_type
  }

  /** 是否为会员 */
  get isVip(): boolean {
    return this.isDiamondVIP || this.normalVIP
  }

  /** 是否为可牛超级会员 */
  get isSuperKeniuVip(): boolean {
    const keniuInfo: string[] = [KENIU_VIP_TYPE.normal, KENIU_VIP_TYPE.diamond]

    return keniuInfo.includes(this.getPriorityMemberInfo?.vip_type)
  }

  /** 普通会员 */
  get normalVIP(): boolean {
    const normalInfo = [KENIU_VIP_TYPE.normal, this.config.getMemberTypeInfo.normal]

    return normalInfo.includes(this.getPriorityMemberInfo?.vip_type)
  }

  /** 钻石会员 */
  get isDiamondVIP(): boolean {
    const diamondInfo = [KENIU_VIP_TYPE.diamond, this.config.getMemberTypeInfo.diamond]

    return diamondInfo.includes(this.getPriorityMemberInfo?.vip_type)
  }

  /**
   * @description 是否为单品普通会员
   */
  get isSingleNormalMember (): boolean {
    return Object.keys(this.singleNormalMemberInfo).length > 0
  }

  /**
   * @description 是否为单品钻石会员
   */
  get isSingleDiamondMember (): boolean {
    return Object.keys(this.singleDiamondMemberInfo).length > 0
  }

  /**
   * @description 是否为单品会员
   */
  get isSingleMember (): boolean {
    return Object.keys(this.singleDiamondMemberInfo).length > 0 || Object.keys(this.singleNormalMemberInfo).length > 0
  }

  /**
   * @description 是否为可牛超级普通会员
   */
  get isKeniuSuperNormalMember (): boolean {
    return Object.keys(this.keniuSuperNormalMemberInfo).length > 0
  }

  /**
   * @description 是否为可牛超级钻石会员
   */
  get isKeniuSuperDiamondMember (): boolean {
    return Object.keys(this.keniuSuperDiamondMemberInfo).length > 0
  }

  /**
   * @description 是否为可牛超级会员
   */
  get isKeniuSuperMember (): boolean {
    return Object.keys(this.keniuSuperMemberInfo).length > 0 || Object.keys(this.keniuSuperNormalMemberInfo).length > 0
  }

  /** 续费用户 */
  get isContinuous(): boolean {
    return !!this.getPriorityMemberInfo?.is_continuous
  }

  /** 会员有效期时间戳 */
  get memberExpiredMs(): number {
    return Number(this.getPriorityMemberInfo?.expired_at) ?? 0
  }

  /** 用户头像 */
  get avatar(): string {
    return this.userInfo?.user_info?.avatar || ''
  }

  /** 用户昵称 */
  get nickname (): string {
    return this.userInfo?.user_info?.nickname || ''
  }

  /** 唯一标识 */
  get token(): string {
    return this.cookie.getToken
  }

  /** 唯一令牌 */
  get openId():string {
    return this.cookie.getOpenId
  }

  /** 会员类型集合 */
  get vip_Info (): VipInfoList[] {
    return this.userInfo?.vip_info_list || []
  }

  /** 权益信息集合 */
  get permission_list_info (): PermissionList[] {
    return this.userInfo?.permission_list || []
  }

  /**
   * @description 单品普通会员信息
   */
  get singleNormalMemberInfo (): VipInfoList {
    const currentProductMemberInfo = this.config.getMemberTypeInfo
    const memberArr = [currentProductMemberInfo.normal]

    return this.querySomeMemberInfo(memberArr)
  }

  /**
   * @description 单品钻石会员信息
   */
  get singleDiamondMemberInfo (): VipInfoList {
    const currentProductMemberInfo = this.config.getMemberTypeInfo
    const memberArr = [currentProductMemberInfo.diamond]

    return this.querySomeMemberInfo(memberArr)
  }

  /**
   * @description 可牛超级普通会员信息
   */
  get keniuSuperNormalMemberInfo (): VipInfoList {
    const memberArr = ['keniu_normal']

    return this.querySomeMemberInfo(memberArr)
  }

  /**
   * @description 可牛超级钻石会员信息
   */
  get keniuSuperDiamondMemberInfo (): VipInfoList {
    const memberArr = ['keniu_diamond']

    return this.querySomeMemberInfo(memberArr)
  }

  /**
   * @description 单品会员信息(包含普通/钻石)
   */
  get singleMemberInfo () {
    const currentProductMemberInfo = this.config.getMemberTypeInfo
    const memberArr = [currentProductMemberInfo.diamond, currentProductMemberInfo.normal]

    return this.querySomeMemberInfo(memberArr)
  }

  /**
   * @description 单品会员信息(包含普通/钻石)
   */
  get keniuSuperMemberInfo () {
    const memberArr = ['keniu_diamond', 'keniu_normal']

    return this.querySomeMemberInfo(memberArr)
  }

  /**
   * @description 查看对应会员信息
   */
  private querySomeMemberInfo (arr: string[]): VipInfoList {
    for (const p of arr) {
      for (const c of this.vip_Info) {
        if (c.vip_type === p) {
          return c
        }
      }
    }

    return {} as VipInfoList
  }

  /**
   * @description 根据优先级获取会员信息
   */
  get getPriorityMemberInfo(): VipInfoList {
    const currentProductMemberInfo = this.config.getMemberTypeInfo
    // 会员优先级展示顺序 可牛钻石 => 可牛普通 => 产品钻石 => 产品普通
    const memberArr = ['keniu_diamond', 'keniu_normal', currentProductMemberInfo.diamond, currentProductMemberInfo.normal]
  
    for (const p of memberArr) {
      for (const c of this.vip_Info) {
        if (c.vip_type === p) {
          return c
        }
      }
    }
  
    return {} as VipInfoList
  }
  
  /** 可牛看图-是否拥有抠图权限 */
  get isKoutuAuth () {
    return this.KoutuPermissionInfo().some(e => e.left_cnt)
  }

  /** 计算抠图赠送、独立次数 */
  countKoutuNum () {
    const koutuInfo = this.KoutuPermissionInfo()
    const numInfo = {
      [permissionType.give]: 0,
      [permissionType.stand]: 0
    }

    koutuInfo.forEach(e => {
      numInfo[e.permission_type] = e.left_cnt
    })

    return numInfo
  }

  /**
   * @description 查看抠图相关权益信息
   */
  private KoutuPermissionInfo (): PermissionList[] {
    const arr = []
    const memberArr = [permissionType.give, permissionType.stand]
    for (const p of memberArr) {
      for (const c of this.permission_list_info) {
        if (c.permission_type === p) {
          // 更多会员信息展示统一参数判断
          arr.push(c)
        }
      }
    }
  
    return arr as PermissionList[]
  }

  /**
   * @description 处理时间文案的会员信息
   */
  get formatDateVipInfo () {
    // 可牛看图 - 智能抠图次数兼容展示处理
    const numInfo = this.countKoutuNum()
    const nowVipInfo = this.vip_Info.map(e => {
      if (e.vip_type === 'fastpic_normal') {
        return { ...e, ...numInfo }
      }

      return e
    })

    console.log('nowVipInfo~~~~~~~', nowVipInfo);
    
    return nowVipInfo.map(e => ({ ...e, expired_at: this.handleMoreMemberDateText(e) })) || []
  }

  /**
   * @description 超级更多会员信息日期文案
   */
  handleMoreMemberDateText (obj: VipInfoList) {
    const is_diamond = obj.page_detail?.is_diamond
    return is_diamond ? '永久' : this.common.handleDate(Number(obj.expired_at))
  }

  /**
   * @description 会员剩余时长文案
   */
  memberDateText(date = ''): string {
    return (this.getPriorityMemberInfo?.page_detail?.is_diamond
      ? '永久'
      : `${this.common.handleDate(Number(date) || this.memberExpiredMs)}到期`)
  }

  /** 更新客户端方法所需用户参数 */
  updateBridgeNeedUserParams () {
    const params = {
      open_id: this.openId,
      token: this.token,
      type: this.loignType,
      noRefresh: 1
    }

    this.bridge.setDefaultUserParams(params)
  }

  /** 订阅退出登录 */
  onLogoutSubscribe () {
    this.onLogout.subscribe(() => {
      this.bridge.exit()
      this.cookie.removeUserInfoLongTerm()
      this.bridge.refreshPage()
    })
  }

  /**
   * @description 请求用户信息
   */
  requestUserInfo(params = {}) {
    return this.http.post<UserInfoResponseData>('/usermix/user_info', params).then((res) => {
      const userInfo = res
      const { token, open_id } = userInfo?.user_info

      this.userInfo = userInfo
      this.cookie.setUserInfoLongTerm({ token, open_id, userInfo })
      this.onUserInfoChanged.next()
    })
  }

  /**
   * @description 关闭会员中心优惠券弹泡 - 根据svrid最后一位进行判断
   * A：关闭会员中心时，有优惠券弹泡
   * B：关闭会员中心时，没有优惠券弹泡（默认）
   */
  async couponAbTest () {
    const svrid = this.cookie.svrid
    const svridLastChar = svrid.charAt(svrid.length - 1)
    const abTestUrl = 'https://front-resources.kpdf.cn/knpdf/coupon-plus-abtest.json'
    let flag = 0

    try {
      const res = await axios.get(abTestUrl)
      if (res && res.status === 200) {
        const { data } = res
        if (data.coupon.includes(svridLastChar)) {
          flag = 1
        }
      }
      return flag
    } catch (error) {
      console.error(' == ab测试 == ', error);
      return flag
    }
  }

  /** 请求会员中心用户评论接口 */
  requestCommentInfo (params) {
    return this.http.post<CommentInfoResponseData>('/keniuactivity/keniupdf/comment', params).then(res => {
      if (res.comments) {
        return res
      }

      return {
        comments: [],
        has_more: false
      }
    })
  }

  /**
   * @description 会员中心用户评论AB测试 - 根据svrid最后一位进行判断
   * A：用户外网现状（无评论）
   * B：增加评论，默认显示
   * C：不增加评论，默认不显示
   */
  async commentABTest () {
    const svrid = this.cookie.svrid.toLocaleLowerCase()
    const tryno = this.cookie.tryno
    const svridLastChar = svrid.charAt(svrid.length - 1)
    let abTestUrl = 'https://front-resources.kpdf.cn/knpdf/comment-abtest-pro.json'
    if (process.env.STAGE === 'test' || process.env.NODE_ENV === 'development') {
      abTestUrl = 'https://front-resources.kpdf.cn/knpdf/comment-abtest-dev.json'
    }
    let flag = commentABTestEnum.ATest

    try {
      const res = await axios.get(abTestUrl)
      if (res && res.status === 200) {
        const { data } = res
        
        data.forEach(item => {
          if (item.tryno == tryno) {
            if (item.range.BRange.includes(svridLastChar)) {
              flag = commentABTestEnum.BTest
            } else if (item.range.CRange.includes(svridLastChar)) {
              flag = commentABTestEnum.CTest
            }
          }
        })
      }
    } catch (error) {
      console.log(' -- comment abtest api error -- ', error);
    }
    return flag
  }
}
