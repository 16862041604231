import { CookieService } from '@/services/cookie.service';
import { ReportService } from '@/services/report.service';
import { Injectable } from '@tanbo/vue-di-plugin';
import { KantuHttp } from './koutu-editor-http.service';

export interface AuthorizeResponseData {
    /** 授权码 */
    authorized_code: string
  }
  
export interface CertRequestParams {
    /** 开放API能力编码 */
    ability_code: string
    /** 作品ID */
    works_id: string
  }
  
export interface CertResponseData {
  /** 权益使用凭证，有效期10分钟 */
  permission_use_cert: string
}

/** 保存图片错误code（用于上报） */
export const save_err_ret = {
  /** 会员无抠图次数，打开智能抠图次数购买页  */
  vip_noNum: '40',
  /** 未登录 || 非会员无抠图次数，打开会员中心 */
  noLogin_noVip: '41'
}

@Injectable()
export class KoutueEditorService {
  report = this.reporter.makeReporter('infockeniu_knpic_gaoding_cutout:215 product_id:int version:int usertype:int source:int act:int cutout_errcode:string save_errcode:string vip_version:int', {
    usertype: 0,
    source: this.cookie.source,
    act: 0,
    cutout_errcode: '',
    save_errcode: ''
  })

  constructor(
        private http: KantuHttp,
        private cookie: CookieService,
        private reporter: ReportService
  ) {

  }

  /**
 * 上传附件转base64
 * @param {File} file 文件流
 */
  fileByBase64 (file, callback) {
    const reader = new FileReader();
    // 传入一个参数对象即可得到基于该参数对象的文本内容
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      // target.result 该属性表示目标对象的DataURL
      console.log(e.target.result);
      callback(e.target.result)
    };
  }

  /**
   * @description 请求授权码（用于我方和sdk账号联系）
   */
  requestAuthorize () {
    return this.http.post<AuthorizeResponseData>('/cutout/get_authorized_code', {})
  }

  /**
   * @description 请求权益扣减（用于扣费）
   */
  requestGetUseRightCert (params: CertRequestParams) {
    return this.http.post<CertResponseData>('/cutout/get_permission_use_cert', params)
  }
}
