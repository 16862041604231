export async function callNewRequest (param) {
  const response = await Promise.race([clientCallRequest({ fnName: 'checkMethod', params: { type: param.fnName } }), getTimeoutPromise()])

  const available = !!response?.result
  const timeout = !!response?.timeout

  const reason = timeout ? '超时' : '失败'

  if (available) {
    const methodResponse = await Promise.race([clientCallRequest(param), getTimeoutPromise()])

    if (methodResponse.timeout) {
      return Promise.reject(new Error('接口调用超时，客户端暂不支持此接口调用：' + param.fnName))
    }

    return Promise.resolve(methodResponse)
  }
  return Promise.reject(new Error(`检查接口调用${reason}，此客户端不支持该接口：${param.fnName || 'unknownName'}`))
}

function clientCallRequest (param) {
  return new Promise((resolve, reject) => {
    const fnName = param.fnName
    if (!fnName) {
      reject(new Error('no fnName'))
      return
    }

    let params = param.params || ''
    if (typeof params === 'object') {
      params = JSON.stringify(params)
    }

    const clientParams = {
      fnName,
      params,

      onSuccess: (res) => {
        let result = res
        if (typeof res === 'string') {
          try {
            result = JSON.parse(res)
          } catch (err) {
            console.log('err')
          }
        }

        resolve(result)
      },

      onFailure: (errorCode, errorMsg) => {
        const result = {
          errorCode,
          errorMsg
        }

        reject(result)
      }
    }

    // 新版本直接CallRequest
    if (window.CallRequest) {
      window.CallRequest(clientParams)
    } else if (window.external && window.external.CallRequest) {
      // 老版本window.external.CallRequest
      window.external.CallRequest(clientParams)
    } else {
      reject(new Error('处于浏览器环境中'))
    }
  })
}

function getTimeoutPromise () {
  // eslint-disable-next-line promise/param-names
  return new Promise((resolve, _) => {
    setTimeout(() => {
      resolve({ result: false, timeout: true })
    }, 3000)
  })
}
