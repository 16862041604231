interface IgetMemberInterestImgObj {
  /** 头部图片链接 */
  head: string
  /** 内容图片链接 */
  content: string
}

interface IgetProductInfo {
  /** 唯一id */
  appid: string
  /** 令牌 */
  secret: string
  /** 版本 */
  version: number
}

interface IgetMemberTypeInfo {
  /** 普通会员 */
  normal: string
  /** 钻石会员 */
  diamond: string
}

interface IgetOldAccountLoginInfo {
  /** 图片 */
  img: string
  /** 文案 */
  text: string
  /** id */
  id: string
}

/**
 * 产品id枚举
 * 101被海外项目占用
 */
export enum product_id_info {
  /** 可牛看图 */
  kantu = 100,
  /** 电脑助手 */
  couputerHelper = 102,
  /** 可牛pdf */
  pdf = 103,
  /** 可牛视频转换器 */
  video = 105,
  /** PDF转换器 */
  pdfConvert = 111
}

/** 登录类型枚举 */
export enum login_type_info {
  wechat = 1, /** 微信 */
  qq = 2, /** QQ */
  phone = 3, /** 手机 */
  fast = 4 /** 快速登录 -- 上报使用 */
}

export const login_type_text_info = {
  [login_type_info.wechat]: '微信',
  [login_type_info.qq]: 'QQ',
  [login_type_info.phone]: '手机'
}

/** 获取logo集合 */
export const logoInfo = {
  [product_id_info.kantu]: require('@/assets/images/kantu/logo.png'),
  [product_id_info.couputerHelper]: require('@/assets/images/computer_helper/logo.png'),
  [product_id_info.pdf]: require('@/assets/images/pdf/logo.png'),
  [product_id_info.video]: require('@/assets/images/video/logo.png'),
  [product_id_info.pdfConvert]: require('@/assets/images/pdf_convert/logo.png'),
}

export const getTitleInfo = {
  [product_id_info.kantu]: '可牛看图',
  [product_id_info.couputerHelper]: '可牛电脑助手',
  [product_id_info.pdf]: '可牛PDF',
  [product_id_info.video]: '可牛视频转换器',
  [product_id_info.pdfConvert]: '可牛PDF转换器',
}

/** 客户端软件名称集合(用于安装) */
export const getSoftwareName = {
  [product_id_info.kantu]: 'knpic',
  [product_id_info.couputerHelper]: 'knoptasst',
  [product_id_info.pdf]: 'knpdf',
  [product_id_info.video]: 'knfastvc',
  [product_id_info.pdfConvert]: 'knpdf',
}

/** 获取下载对话框图标 */
export const getInstallModalIcon = {
  [product_id_info.kantu]: require('@/assets/images/kantu/install_modal_icon.png'),
  [product_id_info.couputerHelper]: require('@/assets/images/computer_helper/install_modal_icon.png'),
  [product_id_info.pdf]: require('@/assets/images/pdf/install_modal_icon.png'),
  [product_id_info.video]: require('@/assets/images/video/install_modal_icon.png'),
  [product_id_info.pdfConvert]: require('@/assets/images/pdf_convert/install_modal_icon.png'),
}

/**
 * @description 配置服务
 * 根据载入不同得配置初始化项目，当前（可牛看图、可牛PDF、可牛电脑助手）都根据配置来初始化，共用一套域名
 * 在未来当前配置无法满足运营需求，则再做项目拆分 （根据product_id做区分）
 */
export abstract class ConfigService {
  /** 渠道号 */
  getTryno: string
  /** 获取logo集合 */
  logoInfo: string
  /** 获取标题集合 */
  getTitleInfo: string
  /** 获取默认套餐id */
  getPackageId: string
  /** 获取区分场景套餐id */
  getScenePackageId: string
  /** 获取会员中心标题 */
  getTitle: string
  /** appid */
  wxAppId: string
  /** logo */
  getLogo: string
  /** 获取公众号登录引导图片 */
  getOfficialLoginImg: string
  /** 获取头部二维码图片 */
  getHeaderQrCodeImg: string
  /** 获取官网链接 */
  getWebsiteUrl: string
  /** 会员权益描述 */
  getMemberInterestDesc: string
  /** 获取会员协议链接 */
  getMemberProtocolLink: string
  /** 获取会员权益图片集合 */
  getMemberInterestImgObj: IgetMemberInterestImgObj
  /** 获取产品信息 */
  getProductInfo: IgetProductInfo
  /** 获取会员类型信息 */
  getMemberTypeInfo: IgetMemberTypeInfo
  /** 请求url */
  getRequestUrl: string
  /** 获取老帐号登录信息 */
  getOldAccountLoginInfo?: IgetOldAccountLoginInfo
}
