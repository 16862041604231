
import { defineComponent, reactive, computed, onMounted, ref, onUnmounted } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { UserService, commentABTestEnum } from '@/services/user.service'
import { UserInfoService } from '@/views/custom-made/pdf-convert/user-info.service'
import { HomeService } from '@/views/home/home.service'
import { TabService } from '@/views/home/_components/tab.service'

import Pay from '../pay/pay.vue'
import Header from '@/components/header/header.vue'
import UserInfo from '@/views/custom-made/pdf-convert/user-info.vue'
import Interests from '../interests/interests.vue'
import InterestAndComment from '../interests/InterestAndComment.vue'
import { scrollTo } from '@/utils/scroll'

/** 会员中心 */
export default defineComponent({
  name: 'PdfConvert',
  components: {
    Pay,
    UserInfo,
    Interests,
    Header,
    InterestAndComment,
  },
  setup() {
    const injector = useReflectiveInjector([HomeService, UserInfoService, TabService])
    const service = injector.get(HomeService)
    const userInfo = injector.get(UserInfoService)
    const user = injector.get(UserService)
    const tab = injector.get(TabService)

    const viewModel = reactive({
      isVip: false,
      isRenew: false,
      abTestEnum: commentABTestEnum,
      isShowComment: commentABTestEnum.ATest, // 是否显示评论AB测试（默认不显示）
      isShowInterests: false, // AB测试结束再展示权益模块
    })

    /** 滚动节点 */
    const scrollNode = ref(null)

    /** 根据vip状态配置class, 这里为了兼容样式 */
    const heightClass = computed(() => {
      return viewModel.isVip && !viewModel.isRenew ? 'hg_690' : 'hg_650' // 超级会员版本
    })

    /** 订阅续费状态变化 */
    const unRenew = userInfo.onRenew.subscribe(() => (viewModel.isRenew = true))

    /** 订阅用户信息变化 */
    const unUserInfoChanged = user.onUserInfoChanged.subscribe(() => {
      viewModel.isVip = user.isVip
      viewModel.isRenew = false
    })

    /** 订阅tab切换变化 */
    tab.onTabToggle.subscribe(() => {
      scrollTo(0, 0, scrollNode.value)
    })

    onMounted(() => {
      service.report()

      // 会员用户，直接展示权益
      if (viewModel.isVip) {
        viewModel.isShowInterests = true
      } else {
        user.commentABTest().then(res => {
          viewModel.isShowComment = res
          viewModel.isShowInterests = true
        })
      }
    })

    onUnmounted(() => {
      unRenew.unsubscribe()
      unUserInfoChanged.unsubscribe()
    })

    /**
     * @description 是否展示支付组件
     */
    const isPay = computed(() => !viewModel.isVip || viewModel.isRenew)

    const handleScroll = (event) => {
      user.scrollSub.next(event)
    }

    return {
      isPay,
      viewModel,
      scrollNode,
      heightClass,
      user,
      handleScroll
    }
  }
})
