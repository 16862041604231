import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "new-pay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PayPackages = _resolveComponent("PayPackages")!
  const _component_CouponList = _resolveComponent("CouponList")!
  const _component_PaySettings = _resolveComponent("PaySettings")!
  const _component_PayQrcode = _resolveComponent("PayQrcode")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.couponABTest && _ctx.tabType !== 'super')
      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_PayPackages, { "setting-padding-num": _ctx.settingPaddingNums }, null, 8, ["setting-padding-num"]),
          _createVNode(_component_CouponList)
        ], 64))
      : (_openBlock(), _createBlock(_component_PaySettings, {
          key: 1,
          "setting-padding-num": _ctx.settingPaddingNums
        }, null, 8, ["setting-padding-num"])),
    _createVNode(_component_PayQrcode)
  ]))
}