import { Http } from '@zhfe/common-library'
import { Injectable } from '@tanbo/vue-di-plugin'
import { CookieService } from '@/services/cookie.service';
import { BridgeService } from '@/services/bridge.service';
import { UserService } from '@/services/user.service';
import {ReportService} from '@/services/report.service';
import {ConfigService} from '@/services/config.service';
import { OcpcType } from '@/views/login/login.service'

export interface PayWechatQrCodeRequestParams {
  /** 支付的APP，由服务端分配 */
  payapp: string
  /** 付费产品ID，预先配置好 */
  product_id: string
  /** 见备注 */
  client_extra?: any
}

export interface WxResp {
  /** 用于生成支付二维码，然后提供给用户进行扫码支付。 */
  code_url: string
}

export interface PayWechatQrCodeResponseData {
  /** 预订单ID，全局唯一，以后查询也用这个ID */
  order_id: string
  /** 金额，单位分 */
  fee: number
  /** 过期时间，客户端只能在这个时间之前支付 */
  expires: string
  /** 微信调起支付需要的参数 */
  wx_resp: WxResp
  /** 货币，默认CNY */
  fee_type?: string
}

export interface AliPayRequestParams {
  /** 支付的APP，由服务端分配 */
  payapp: string
  /** 付费产品ID，预先配置好 */
  product_id: string
  /** 手机网页支付：支付宝处理完请求后，当前页面自动跳转到商户网站里指定页面的http路径。 */
  return_url: string
  /** 上报信息 */
  client_extra?: any
}

export interface AliResp {
  /** 用于生成支付二维码，然后提供给用户进行扫码支付。 */
  code_url: string
}

export interface AliPayResponseData {
  /** 预订单ID，全局唯一，以后查询也用这个ID */
  order_id: string
  /** 金额，单位分 */
  fee: number
  /** 过期时间，客户端只能在这个时间之前支付 */
  expires: string
  /** 支付宝调起支付需要的参数 */
  ali_resp: AliResp
  /** 货币，默认CNY */
  fee_type?: string
}

export interface PayResultRequestParams {
  /** 订单ID */
  order_id: string
  /** 支付app，服务端分配 */
  payapp: string
}

export interface PayResultOrder {
  /** 支付平台：wx, ali, apple */
  platform: string
  /** 支付方式：app, contract, h5, qrcode, iap, ... */
  pay_type: string
  order_id: string
  /** 金额，单位分 */
  fee: number
  create_time: string
  /** 状态：paying-支付中；paid-已支付；refunding-退款中；refund-已退款；fail-支付失败 */
  status: string
  /** 产品ID */
  product_id: string
  /** 产品信息 */
  // product_info: ProductInfo
  /** 关联的合约ID */
  contract_id?: string
  /** 货币，默认CNY */
  fee_type?: string
  pay_time?: string
  refund_time?: string
}

export interface PayResultResponseData {
  order: PayResultOrder
}

export interface TurnShortLinkRequestParams {
  /** 要缩短的URL */
  url: string
  /** 场景(login, pay)  监控统计数据使用 */
  scenes?: string
}

export interface turnShortLinkResponseData {
  /** 被缩短的url */
  short_url: string
}

@Injectable()
export class PayQrcodeService {
  /** 支付上报 */
  client_extra = {
    version: this.reporter.report_version, /** 埋点版本号 */
    vip_version: this.cookie.vipVersion, /** 会员客户端版本 */
    show_from: this.cookie.show_from, /** 会员页展示来源 */
    fun_from: this.cookie.fun_from, /** 权益来源 */
    vip_status: this.user.isVip ? '1' : '2', /** 是否会员 1：是，2：否 */
    current_uuid: this.cookie.uuid, /** 产品的uuid */
    current_tryno: this.cookie.tryno, /** 产品的tryno */
    current_svrid: this.cookie.svrid, /** 产品的svrid */
    tid1: String(this.bridge.tidInfo.tid1), /** 主通道号（内通道号） */
    tid2: String(this.bridge.tidInfo.tid2), /** 副通道号（内通道号） */
    tod1: String(this.bridge.tidInfo.tod1), /** 主通道号（外通道号） */
    tod2: String(this.bridge.tidInfo.tod2), /** 副通道号（外通道号） */
    installtime: this.cookie.installTime /** 安装时间 */
  }

  constructor(
    private http: Http,
    private cookie: CookieService,
    private bridge: BridgeService,
    private user: UserService,
    private reporter: ReportService,
    private config: ConfigService
  ) {
  }

  ocpcreport = this.reporter.makeReporter('duba_jgpdf_sem_ocpc:8167 activation:string signin:string buy:string error:string product_id:string version:byte vip_version:int', {
    activation: '0',
    signin: '0',
    buy: '0',
    error: '0',
  })

  reportPaySuccessToOcpc (url: string) {
    // this.ocpcHttp.post('/ocpcapi/api/uploadConvertData', {
    //   token: 'TAlmhzqN5Oe5TGzihvpODRhHcV0WOHcc@gXfc6Y4tY9UOv8NQMmvGIPBssmVGSZjf',
    //   conversionTypes: [{
    //     logidUrl: url,
    //     newType: OcpcType.Buy
    //   }]
    // }).then((res) => {
    //   this.ocpcreport(res.header!.status ? {buy: '2', error: res.header.errors.code + '' } : {buy: '1'})
    // })
  }

  /**
   * @description 请求微信支付二维码
   */
  requestWeChatPayQrcode (params: PayWechatQrCodeRequestParams) {
    return this.http.post<PayWechatQrCodeResponseData>('/baoquvip/payv2/wx/native', params)
  }

  /**
   * @description 请求支付宝支付二维码
   */
  requestAliPayQrcode (params: AliPayRequestParams) {
    return this.http.post<AliPayResponseData>('/baoquvip/payv2/ali/native', params)
  }

  /**
   * @description 请求生成短链接
   */
  requestTurnShortLink (params: TurnShortLinkRequestParams) {
    return this.http.post<turnShortLinkResponseData>('/url/shortable', params)
  }

  /**
   * @description 请求支付结果
   */
  requestPayResult (params: PayResultRequestParams) {
    return this.http.post<PayResultResponseData>('/baoquvip/payv2/order/query', params)
  }
}
