import { getUrlParam } from '@shared/utils/base'
import { ConfigService, product_id_info } from '@/services/config.service';

export class CouputerHelperService extends ConfigService {
  /** 获取区分场景套餐id */
  get scenePackageId () {
    const sceneName = getUrlParam('scene')
    const scene_info = {
      home: '001',
      wxmc: '010', // 公众号会员中心
      wxcmc: 'pcmgr_gzh_discount', // 公众号优惠券key
      adblock: '002', // 弹窗拦截
      comopt: '003', // 一键优化
      privacy: '004', // 隐私清理
      sysopt: '005', // 电脑加速
      sysslim: '006', // C盘瘦身
      trashclean: '007', // 垃圾清理
      cleanking: '008', // 碎片清理
      software: '009' // 软件管理
    }

    return scene_info[sceneName] ?? '001'
  }

    getTryno= '6300'
    getTitle= '可牛电脑助手'
    wxAppId= 'wxa1da281682d9e804'
    getPackageId= product_id_info.couputerHelper + this.scenePackageId
    getScenePackageId= this.scenePackageId
    getLogo= require('@/assets/images/computer_helper/logo.png')
    getOfficialLoginImg= require('@/assets/images/computer_helper/guide_bg.png')
    getHeaderQrCodeImg= require('@/assets/images/computer_helper/qrcode_official.png')
    getWebsiteUrl= 'https://zs.keniu.com/'
    getMemberInterestDesc= '提供电脑一键优化方案、垃圾清理、电脑加速、弹窗拦截、隐私清理、碎片清理、磁盘瘦身、软件卸载、流氓软件清理等权益'
    getMemberProtocolLink= 'https://www.keniu.com/privacy/knastVipLicense.html'
    getMemberInterestImgObj= {
      head: 'https://api-cos.keniu.com/interest/pcmgr/pcmgr_compare_header.png',
      content: 'https://api-cos.keniu.com/interest/pcmgr/pcmgr_compare.png'
    }

    getProductInfo= {
      appid: '102',
      secret: 'x00unnidxaeldx10kpnye2idzxa026ap',
      version: 1
    }

    getMemberTypeInfo= {
      normal: 'pcmgr_normal',
      diamond: 'pcmgr_diamond'
    }

  getRequestUrl = 'https://api-pcmgrvip.keniu.com'
}
