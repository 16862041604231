
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import { useReflectiveInjector, Type } from '@tanbo/vue-di-plugin';
import { UserService } from '../../services/user.service';
import { ActivityConfig, HeaderService } from './header.service'
import { ConfigService, product_id_info } from '@/services/config.service';
import { BridgeService, OLD_ACCOUNT_STATUS } from '@/services/bridge.service';
import { CookieService } from '@/services/cookie.service';
import RetainDialog from '../RetainDialog/RetainDialog.vue';
import { CommonService } from '@/services/common.service';

export default defineComponent({
  name: 'Header',
  components: { RetainDialog },
  setup() {
    const injector = useReflectiveInjector([HeaderService]);
    const user = injector.get(UserService);
    const config = injector.get(ConfigService as Type<ConfigService>);
    const cookie = injector.get(CookieService);
    const bridge = injector.get(BridgeService);
    const headerService = injector.get(HeaderService)
    const commonService = injector.get(CommonService)
    const isShowOldAccount = ref(true);
    const isShowDialog = ref(false)
    const viewModel = reactive({
      logo: config.getLogo,
      isLogin: user.isLogin,
      title: config.getTitle,
      productId: cookie.productId,
      headerQrCodeImg: config.getHeaderQrCodeImg
    });
    const openDialogDate = ref<string | undefined>(undefined)
    const activityConfig = ref<ActivityConfig | undefined>(undefined)
    if (cookie.productId === String(product_id_info.pdfConvert)) {
      headerService.getActivityConfig('pdfconvert-closevip-popup')
    } else if (cookie.productId === String(product_id_info.pdf)) {
      // 针对可牛PDF，新增AB测试
      user.couponAbTest().then(abTestRes => {
        abTestRes && headerService.getActivityConfig('pdf-closevip-popup')
      })
    }

    user.onLogout.subscribe(() => {
      viewModel.isLogin = user.isLogin;
    });

    headerService.ActivityConfig.subscribe((data) => {
      activityConfig.value = data
    })

    headerService.openRetainDialogDate.subscribe((data) => {
      openDialogDate.value = data
    })
    /** 是否展示兑换码 */
    const showExchange = computed(() => {
      const product_id_box = [product_id_info.pdf, product_id_info.video, product_id_info.couputerHelper];
      const product_id = Number(cookie.productId);
      const vip_version = Number(cookie.vipVersion);
      if (product_id_box.includes(product_id)) {
        return vip_version >= 2;
      }
      // 涉及到兑换成功后需要刷新单个窗口，新版本客户端支持，老版本不支持故做屏蔽
      if (product_id === product_id_info.kantu) {
        // 看图和其他客户端初始版本不一样，起初上线的时候没有统一为1导致需要维护不同过的版本，坑爹
        return vip_version >= 3;
      }
      return false;
    });
    function clickLogout() {
      user.onLogout.next();
    }
    function clickClose() {
      const today = commonService.handleDate(new Date().getTime())
      if (user.isLogin && !user.isVip && activityConfig.value !== undefined && openDialogDate.value !== undefined && openDialogDate.value !== today) {
        isShowDialog.value = true
        headerService.updateOpenRetainDialogDate(today)
      } else {
        window.close();
      }
    }
    /**
     * @description 打开兑换码窗口
     */
    function clickExchange() {
      const { origin, search } = location;
      bridge.openOptionWindow({
        url: `${origin}/exchange${search}`,
        open_web: 'exchange',
        width: 620,
        height: 440
      });
    }
    /**
     * @description 切换老帐号登录
     */
    function switchOldAccountLogin() {
      if (viewModel.isLogin) {
        bridge.exit();
        cookie.removeUserInfoLongTerm();
      }
      bridge.switchProduct('login');
    }
    onMounted(() => {
      bridge.isShowOldAccount().then(value => {
        const isShow = value.result === OLD_ACCOUNT_STATUS.SHOW;
        isShowOldAccount.value = isShow;
      });
    });
    return {
      product_id_info,
      isShowOldAccount,
      viewModel,
      showExchange,
      clickClose,
      clickLogout,
      clickExchange,
      switchOldAccountLogin,
      isShowDialog
    };
  },
});
