
import { computed, defineComponent, onMounted, reactive } from 'vue'
import { useReflectiveInjector, Type } from '@tanbo/vue-di-plugin'
import { UserService } from '../../../services/user.service'
import { PageDetail, PayPackages, PaySettingRequestParams, PaySettingsService } from './pay-settings.service'
import { ConfigService } from '@/services/config.service';
import { TabService } from '@/views/home/_components/tab.service';

/** 支付套餐 */
export default defineComponent({
  name: 'PaySettings',
  props: {
    settingPaddingNum: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const injector = useReflectiveInjector()
    const service = injector.get(PaySettingsService)
    const user = injector.get(UserService)
    const config = injector.get(ConfigService as Type<ConfigService>)
    const tab = injector.get(TabService)

    const viewModel = reactive({
      renew: false, /** 续费状态 */
      payIdx: 0, /** 当前悬停下标 */
      hoverIdx: 0, /** 当前悬停的时那个下标 */
      countTimer: null, /** 活动倒计时定时器标识 */
      serverTime: 0, /** 服务端时间戳 */
      showDiamondTip: false, /** 展示钻石会员提示 */
      currentTabType: tab.updateSelectTab(), /** 当前tab类型 */
      defaultProductId: '', /** 默认选中套餐id标识 */
      groupIds: [config.getPackageId], /** 默认套餐id数组 */
      settings: [], /** 套餐列表 */
      coutTimeNum: { /** 活动倒计时数据集合 */
        h: '00',
        m: '00',
        s: '00',
        end: false,
      }
    })

    /** 支付套餐 */
    const paySetting = computed(() =>
      viewModel.settings.filter((_, index) => index < 6)
    )

    /** 支付套餐项跟随套餐个数适配，目前适配3、4、5个套餐的宽度 */
    const payItemClass = computed(() => {
      const len = paySetting.value.length
      const obj = {
        3: {
          outer: 210,
          inner: 206
        },
        4: {
          outer: 153.9,
          inner: 149.9
        },
        5: {
          outer: 120,
          inner: 116
        },
      }

      const idx = len < 3 || len >= 5 ? 5 : len

      return obj[idx]
    })

    /** 当前套餐项 */
    const paySettingItem = computed(() => paySetting.value[viewModel.payIdx])

    onMounted(() => {
      initPackageInfo()
    })

    /** 退出登录状态变化 */
    user.onLogout.subscribe(() => {
      initPaySetting()
    })

    /** 订阅tab切换变化 */
    tab.onTabToggle.subscribe(type => {
      viewModel.currentTabType = type
      initPackageInfo()
    })

    /**
     * @description 发布切换套餐当前项
     */
    function emitPaySettingItem() {
      service.onPaySettingToggle.next(paySettingItem.value)
    }

    /**
     * @description 初始化套餐信息
     */
    async function initPackageInfo () {
      initTabChange()
      await initPaySetting()
      initPayIdx()
    }

    /**
     * @description 初始化tab改变
     */
    function initTabChange () {
      const member_info = {
        normal: [config.getPackageId],
        super: ['199001']
      }

      viewModel.groupIds = member_info[viewModel.currentTabType]
    }

    /**
     * @description 初始化套餐
     */
    function initPaySetting() {
      const params = {
        group_ids: viewModel.groupIds,
        is_continuous_scene: viewModel.renew
      } as PaySettingRequestParams

      return service.requestPaySetting(params).then(res => {
        viewModel.settings = res.pay_groups[0].pay_packages
        viewModel.defaultProductId = res.pay_groups[0]?.default_product_id
        viewModel.serverTime = Number(res.server_timestamp_ms) // 服务端公共库int64转字符串，故做处理

        runCountdown()
      })
    }

    /**
     * @description 初始化套餐下标
     */
    function initPayIdx() {
      const idx = paySetting.value.findIndex(e => viewModel.defaultProductId === e.product_id)

      if (idx > -1) {
        switchPaySettingItem(idx)
      }
    }

    /**
     * @description 切换续费状态
     */
    async function changeRenew() {
      viewModel.renew = !viewModel.renew

      await initPaySetting()
      emitPaySettingItem()
    }

    /**
     * @description 打开协议
     */
    function openXieYi() {
      window.open('//fe.vipguanjia.net/6100/vip_privacy.html')
    }

    /**
     * @description 切换套餐
     */
    function switchPaySettingItem (idx: number) {
      viewModel.payIdx = idx
      emitPaySettingItem()
    }

    /**
     * @description 是否显示对应类型的状态描述
     */
    function showStatusDesc(type: string) {
      const obj = { // 1: 续费 2: 钻石会员描述
        renew: 1,
        diamond: 2
      }

      return paySettingItem.value?.page_detail.selected_type === obj[type]
    }

    /**
     * @description 钻石hover提示
     */
    function isDiamondHoverTip (obj: PayPackages, idx: number) {
      viewModel.hoverIdx = idx
      viewModel.showDiamondTip = obj.page_detail.is_diamond
    }

    /**
     * @description 判断是否开始倒计时
     */
    function runCountdown() {
      const obj = paySetting.value.find(item => item?.page_detail.title_type === 3) || {}
      const { title_start_time = 0, title_end_time = 0 } = obj?.page_detail || {}
      const isExpire = judgeActivityTime(title_start_time, title_end_time)

      if (isExpire) {
        const serverTime = viewModel.serverTime
        // 每次只轮询24个小时
        const endTime = new Date(serverTime).setHours(0, 0, 0, 0) / 1000 + 86400

        startCountTime(endTime, serverTime / 1000)
      }
    }

    /**
     * @description 判断活动时间
     */
    function judgeActivityTime(startTime: number, endTime: number): boolean {
      const serverTime = viewModel.serverTime

      return startTime <= serverTime && serverTime <= endTime
    }

    /**
     * @description 是否展示倒计时
     **/
    function isCountdown(obj: PageDetail) {
      const { title_type, title_start_time, title_end_time } = obj
      const isExpire = judgeActivityTime(title_start_time, title_end_time)

      return title_type === 3 && isExpire && !viewModel.coutTimeNum.end
    }

    /**
     * @description 开始活动倒计时
     */
    function startCountTime(endTime, nowTime) {
      clearInterval(viewModel.countTimer)
      let expiresTime = endTime - nowTime

      againAssignCountTime(expiresTime--)

      viewModel.countTimer = setInterval(() => {
        if (expiresTime >= 0) {
          againAssignCountTime(expiresTime--)
        } else {
          viewModel.coutTimeNum.end = true
          clearInterval(viewModel.countTimer)
        }
      }, 1000)
    }

    /**
     * @description 倒计时重新赋值
     */
    function againAssignCountTime (time: number) {
      viewModel.coutTimeNum = { ...viewModel.coutTimeNum, ...service.analysisTime(time) }
    }

    return {
      viewModel,
      paySetting,
      payItemClass,
      settingPaddingNums: props.settingPaddingNum,
      openXieYi,
      isCountdown,
      changeRenew,
      showStatusDesc,
      isDiamondHoverTip,
      switchPaySettingItem,
    }
  },
})
