
import { BridgeService } from '@/services/bridge.service'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { defineComponent } from 'vue'

// 购买抠图次数对话框
export default defineComponent({
  name: 'BuyKoutuNumDialog',
  setup (_, { emit }) {
    const injector = useReflectiveInjector()
    const bridge = injector.get(BridgeService)

    /**
     * @description 放弃抠图
     */
    function giveUpKoutu () {
      emit('update', false)
    }

    /**
     * @description 购买抠图次数
     */
    function buyKoutu () {
      const { origin } = location

      bridge.openOptionWindow({
        url: `${origin}/koutu-member-center?pid=100&scene=koutu`,
        open_web: 'KoutuMemberCenter',
        width: 700,
        height: 803
      })
      emit('update', false)
    }
    return {
      buyKoutu,
      giveUpKoutu
    }
  }
})
