import { UserService } from '@/services/user.service';
import { Injectable } from '@tanbo/vue-di-plugin';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { getUrlParam } from '@shared/utils/base'

@Injectable()
export class TabService {
  currentTab: Observable<string>;
  private cacheCurrentTab: string | undefined = undefined;
  private changeCurrentTab = new BehaviorSubject<string>(this.cacheCurrentTab)

  constructor (private user: UserService) {
    this.currentTab = this.changeCurrentTab.asObservable()
  }

  /** tab切换下标变化 */
  onTabToggle = new Subject<string>()

  checkedSuperTab = false

  updateCurrentTab (data: string) {
    this.cacheCurrentTab = data
    this.changeCurrentTab.next(data)
  }

  /**
     * @description 更新选中tab
     */
  updateSelectTab () {
    const selTabName = getUrlParam('tab')

    if (!selTabName && this.cacheCurrentTab !== undefined) {
      return this.cacheCurrentTab
    }

    if (this.checkedSuperTab || selTabName === 'super') {
      return 'super'
    }

    return this.user.isSuperKeniuVip ? 'super' : 'normal'
  }
}
