import { getUrlParam } from '@shared/utils/base'
import { ConfigService, product_id_info } from '@/services/config.service';

export class PdfConvertService extends ConfigService {
  /** 获取区分场景套餐id */
  get scenePackageId () {
    const sceneName = getUrlParam('scene')
    const scene_info = {
      home: '001',
      wxmc: '010',
    }

    return scene_info[sceneName] ?? '001'
  }

  getTryno= '6501'
  getTitle= '可牛PDF转换器'
  wxAppId= ''
  getPackageId= product_id_info.pdfConvert + this.scenePackageId
  getScenePackageId= this.scenePackageId
  getLogo= require('@/assets/images/pdf_convert/logo.svg')
  getOfficialLoginImg= require('@/assets/images/pdf_convert/guide_bg.png')
  // 头部客服二维码
  getHeaderQrCodeImg= require('@/assets/images/pdf_convert/qrcode_official.png')
  getWebsiteUrl= 'https://pdf.keniu.com/'
  getMemberInterestDesc= '提供PDF阅读、文字编辑、图片编辑、PDF压缩、PDF去水印、PDF打印、扫描件转换、CAD转换、图片PDF互转、图片OCR等权益'
  getMemberProtocolLink= 'https://www.keniu.com/privacy/knpdfVipLicense.html'
  getMemberInterestImgObj= {
    head: 'https://api-cos.keniu.com/interest/pdf/pdf_compare_header.png',
    content: 'https://api-cos.keniu.com/interest/pdf/pdf_compare.png'
  }

  getProductInfo= {
    appid: '111',
    secret: 'Vpfvm03redJsGjFExTpWG29DWHTEQHvY',
    version: 1
  }

  getMemberTypeInfo= {
    normal: 'pdf_converter_normal',
    diamond: 'pdf_converter_diamond'
  }

  getRequestUrl = 'https://api-pdfvip.keniu.com'
}
