export function scrollTo (endPosition, duration, parent) {
  const pageY = parent.scrollTop
  const startTime = +new Date()
  function run () {
    const time = +new Date() - startTime
    parent.scrollTop = pageY + (endPosition - pageY) * (time / duration)
    run.timer = requestAnimationFrame(run)

    if (time >= duration) {
      parent.scrollTop = endPosition
      cancelAnimationFrame(run.timer)
    }
  }
  requestAnimationFrame(run)
}
