
import { computed, defineComponent, onMounted, reactive, ref} from 'vue'
import { useReflectiveInjector, Type } from '@tanbo/vue-di-plugin'
import { LoginService } from './login.service'
import FastLogin from './_components/fast-login.vue'
import PhoneLogin from './_components/phone-login.vue'
import OfficiaLogin from './_components/official-login.vue'
import { ConfigService, product_id_info } from '@/services/config.service'
import { BridgeService, OLD_ACCOUNT_STATUS } from '@/services/bridge.service'
import { CookieService } from '@/services/cookie.service'

export default defineComponent({
  name: 'Login',
  components: {
    FastLogin,
    PhoneLogin,
    OfficiaLogin,
  },
  setup () {
    const injector = useReflectiveInjector([LoginService])
    const config = injector.get(ConfigService as Type<ConfigService>)
    const bridge = injector.get(BridgeService)
    const cookie = injector.get(CookieService)
    const isShowOldAccount = ref(true)

    const viewModel = reactive({
      logo: config.getLogo,
      title: config.getTitle,
      tabIdx: 0, // tab下标
      officialLoginImg: config.getOfficialLoginImg,
      oldAccountLoginInfo: config.getOldAccountLoginInfo,
      protocolUrl: config.getMemberProtocolLink,
      showFastLogin: false, /** 是否显示快速登录 */
      tabs: ['微信登录', '手机登录'],
      productId: cookie.productId ? Number(cookie.productId) : undefined
    })

    const isShow = computed(() => {
      if (viewModel.oldAccountLoginInfo?.text) {
        if (viewModel.productId === product_id_info.pdf || viewModel.productId === product_id_info.pdfConvert) {
          return isShowOldAccount.value
        }
        return true
      }
      return false
    })

    /**
     * @description 切换tab
     */
    function toggleTab (idx: number) {
      if (viewModel.tabIdx === idx) {
        return
      }

      viewModel.tabIdx = idx
    }

    /**
     * @description 更新快速登录状态
     */
    function updateFastLogin(bool) {
      viewModel.showFastLogin = bool
    }

    function clickClose() {
      window.close()
    }

    /**
     * @description 打开会员协议
     */
    function openMemberProtocol () {
      window.open(viewModel.protocolUrl)
    }

    /**
     * @description 切换老帐号登录
     */
    function switchOldAccountLogin () {
      bridge.switchProduct('login')
    }

    onMounted(() => {
      bridge.isShowOldAccount().then(value => {
        const isShow = value.result === OLD_ACCOUNT_STATUS.SHOW
        isShowOldAccount.value = isShow
      })
    })

    return {
      isShow,
      viewModel,
      toggleTab,
      clickClose,
      updateFastLogin,
      openMemberProtocol,
      switchOldAccountLogin
    }
  }
})
