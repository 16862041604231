
import { defineComponent, ref } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { PayQrcodeService } from '@/views/pay/_components/pay-qrcode.service'
import { PaySettingsService } from '@/views/pay/_components/pay-settings.service'
import PayQrcode from './_components/pay-qrcode.vue'
import PaySettings from './_components/pay-settings.vue'
import CouponList from './_components/CouponList.vue'
import PayPackages from './_components/PayPackages.vue'
import { CouponService } from './_components/coupon.service'
import { CookieService } from '@/services/cookie.service'
import { TabService } from '../home/_components/tab.service'

/** 支付 */
export default defineComponent({
  name: 'Pay',
  components: {
    PaySettings,
    PayQrcode,
    CouponList,
    PayPackages
  },
  props: {
    settingPaddingNum: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const inject = useReflectiveInjector([PayQrcodeService, PaySettingsService, CouponService])
    const cookies = inject.get(CookieService)
    const tab = inject.get(TabService)
    const couponABTest = ref(false)
    const tabType = ref<string | undefined>(undefined)

    const pidList = ['103', '111']
    const coupon = []
    const pid = cookies.productId
    const svrid = cookies.svrid
    const lastChar = Number(svrid.charAt(svrid.length - 1))
    if (pidList.includes(pid) && coupon.includes(lastChar)) {
      couponABTest.value = true
    } else {
      couponABTest.value = false
    }

    tab.onTabToggle.subscribe(type => {
      tabType.value = type
    })

    return {
      settingPaddingNums: props.settingPaddingNum,
      couponABTest,
      tabType
    }
  }
})
