import { Http } from '@zhfe/common-library';
import { Injectable } from '@tanbo/vue-di-plugin'

export enum OCPC_ACTION {
  /** 支付 */
  PAID = 'paid',
  /** 激活 */
  ACTIVE = 'active',
  /** 下载 */
  DOWNLOAD = 'download',
  /** 登录 */
  LOGIN = 'login'
}

@Injectable()
export class OcpcService {
  constructor (private http: Http) {}

  getCode () {
    return this.http.post<void>('/ocpc/report_install_success', {
      short_code: 'bM7N32'
    })
  }

  /**
   * 上报ocpc动作
   * @param long_code
   * @param action
   * @returns
   */
  reportOcpcAction (long_code: string, action: OCPC_ACTION) {
    return this.http.post<void>('/ocpc/report_action', {
      long_code,
      action
    })
  }
}
