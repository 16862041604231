import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_UserInfo = _resolveComponent("UserInfo")!
  const _component_Pay = _resolveComponent("Pay")!
  const _component_Interests = _resolveComponent("Interests")!
  const _component_InterestAndComment = _resolveComponent("InterestAndComment")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_UserInfo, null, {
      default: _withCtx(() => [
        _createVNode(_component_Header)
      ]),
      _: 1
    }),
    _createVNode("div", {
      class: ['wrapper_scroll', _ctx.heightClass],
      ref: "scrollNode",
      onScroll: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleScroll && _ctx.handleScroll(...args)))
    }, [
      _withDirectives(_createVNode(_component_Pay, { "setting-padding-num": 35 }, null, 512), [
        [_vShow, _ctx.isPay]
      ]),
      (_ctx.viewModel.isShowInterests)
        ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
            (_ctx.viewModel.isShowComment === _ctx.viewModel.abTestEnum.ATest || _ctx.viewModel.isVip)
              ? (_openBlock(), _createBlock(_component_Interests, { key: 0 }))
              : (_openBlock(), _createBlock(_component_InterestAndComment, {
                  key: 1,
                  "show-comment": _ctx.viewModel.isShowComment,
                  "user-obj": _ctx.user
                }, null, 8, ["show-comment", "user-obj"]))
          ], 64))
        : _createCommentVNode("", true)
    ], 34)
  ]))
}