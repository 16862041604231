import { getUrlParam } from '@shared/utils/base'
import { ConfigService, product_id_info } from '@/services/config.service';

export class KantuService extends ConfigService {
  /** 获取区分场景套餐id */
  get scenePackageId () {
    const sceneName = getUrlParam('scene')
    const scene_info = {
      home: '001',
      wxmc: '010',
      koutu: '101'
    }

    return scene_info[sceneName] ?? '001'
  }

  getTryno= '6200'
  getTitle= '可牛看图'
  wxAppId= 'wx3a78d7213f1f9ee7'
  getPackageId= product_id_info.kantu + this.scenePackageId
  getScenePackageId= this.scenePackageId
  getLogo= require('@/assets/images/kantu/logo.png')
  getOfficialLoginImg= require('@/assets/images/kantu/guide_bg.png')
  getHeaderQrCodeImg= require('@/assets/images/kantu/qrcode_official.png')
  getWebsiteUrl= 'https://pic.keniu.com/'
  getMemberInterestDesc= '提供图片查看、图片编辑、图片美化、图片压缩、修改尺寸、高清放大、添加水印、图片转格式、批量重命名、高级打印、文字识别等权益'
  getMemberProtocolLink= 'https://www.keniu.com/privacy/keniuVipLicense.html'
  getMemberInterestImgObj= {
    head: 'https://api-cos.keniu.com/interest/pic/pic_compare_header.png',
    content: 'https://api-cos.keniu.com/interest/pic/pic_compare.png',
  }

  getProductInfo= {
    appid: '100',
    secret: '83hk2jah4de4h23fbn283fh23gf28qfh',
    version: 1
  }

  getMemberTypeInfo= {
    normal: 'fastpic_normal',
    diamond: 'fastpic_diamond'
  }

  getOldAccountLoginInfo = {
    img: require('@/assets/images/kantu/old_account_icon.png'),
    text: '极速看图',
    id: '17'
  }

  getRequestUrl = 'https://api-picvip.keniu.com'
}
