export enum PAY_TYPE {
  /** 支付宝 */
  ALI = 1,
  /** 微信 */
  WX = 2
}

export interface Payment {
  /** 支付方式图标 */
  icon: string;
  /** 支付方式名称 */
  name: string;
  /** 支付方式 */
  type: PAY_TYPE;
  desc: string;
  /**
   * 是否默认
   */
  isDefault?: boolean
}
