import { getUrlParam } from '@shared/utils/base'
import { ConfigService, product_id_info } from '@/services/config.service';

export class PdfService extends ConfigService {
  /** 获取区分场景套餐id */
  get scenePackageId () {
    const sceneName = getUrlParam('scene')
    const scene_info = {
      home: '001',
      wxmc: '010',
    }

    return scene_info[sceneName] ?? '001'
  }

  getTryno= '6500'
  getTitle= '可牛PDF'
  wxAppId= ''
  getPackageId= product_id_info.pdf + this.scenePackageId
  getScenePackageId= this.scenePackageId
  getLogo= require('@/assets/images/pdf/logo.png')
  getOfficialLoginImg= require('@/assets/images/pdf/guide_bg.png')
  getHeaderQrCodeImg= require('@/assets/images/pdf/qrcode_official.png')
  getWebsiteUrl= 'https://pdf.keniu.com/'
  getMemberInterestDesc= '提供PDF阅读、文字编辑、图片编辑、PDF压缩、PDF去水印、PDF打印、扫描件转换、CAD转换、图片PDF互转、图片OCR等权益'
  getMemberProtocolLink= 'https://www.keniu.com/privacy/knpdfVipLicense.html'
  getMemberInterestImgObj= {
    head: 'https://api-cos.keniu.com/interest/pdf/pdf_compare_header.png',
    content: 'https://api-cos.keniu.com/interest/pdf/pdf_compare.png'
  }

  getProductInfo= {
    appid: '103',
    secret: 'ukzqkrmx4yul6ydlxjlco3dpmi7tnk4f',
    version: 1
  }

  getMemberTypeInfo= {
    normal: 'pdf_normal',
    diamond: 'pdf_diamond'
  }

  getOldAccountLoginInfo = {
    img: require('@/assets/images/pdf/old_account_icon.png'),
    text: '极光PDF',
    id: '13'
  }

  getRequestUrl = 'https://api-pdfvip.keniu.com'
}
