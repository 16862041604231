
import { useReflectiveInjector } from '@tanbo/vue-di-plugin';
import { defineComponent, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { ActivityConfig, HeaderService, PayPackage } from '../header/header.service';
import PaymentBar from '../payment/index.vue'
import { PAY_TYPE, Payment } from '../payment/types';
import { BridgeService } from '@/services/bridge.service';
import { CookieService } from '@/services/cookie.service';
import { ToastService } from '@/services/toast.service';
import { UserService } from '@/services/user.service';
import { PayQrcodeService } from '@/views/pay/_components/pay-qrcode.service';
import QrcodeVue from 'qrcode.vue'
import { product_id_info } from '@/services/config.service';

export default defineComponent({
  name: 'RetainDialog',
  components: {
    PaymentBar,
    QrcodeVue
  },
  emits: ['pay-success'],
  setup(_, { emit }) {
    let isDestroy = false
    const payments: Payment[] = [
      {
        icon: require('../payment/_images/微信支付.svg'),
        name: '微信支付',
        desc: '开通后会员到期前会自动续费，可随时在微信取消',
        type: PAY_TYPE.WX,
        isDefault: true
      },
      {
        icon: require('../payment/_images/支付宝.svg'),
        name: '支付宝',
        desc: '开通后会员到期前会自动续费，可随时在支付宝取消',
        type: PAY_TYPE.ALI
      }
    ]
    const defaultPayment = payments?.find(item => item.isDefault === true) || payments[0]
    const selectedPayment = ref<Payment | undefined>(defaultPayment)
    const injector = useReflectiveInjector([PayQrcodeService]);
    const headerService = injector.get(HeaderService)
    const service = injector.get(PayQrcodeService)
    const user = injector.get(UserService)
    const bridge = injector.get(BridgeService)
    const toast = injector.get(ToastService)
    const cookie = injector.get(CookieService)
    const viewModel = reactive({
      qrText: '', /** 支付二维码 */
      orderId: '', /** 订单id */
      isVip: user.isVip, /** 是否未会员 */
      isLogin: user.isLogin, /** 是否登录 */
      isExpires: false, /** 支付二维码是否过期 */
      loopPayResultTimer: null, /** 轮询支付结果定时器 */
      countTimer: null, /** 活动倒计时定时器标识 */
      coutTimeNum: { /** 活动倒计时数据集合 */
        h: '00',
        m: '00',
        s: '00',
        end: false,
      },
    })
    const payPackage = ref<PayPackage>(undefined)
    const activityConfig = ref<ActivityConfig>(undefined)
    const show_from = ref(cookie.show_from)

    if (cookie.productId === String(product_id_info.pdf)) {
      show_from.value = '8203'
    } else if (cookie.productId === String(product_id_info.pdfConvert)) {
      show_from.value = '8202'
    }

    headerService.payPackage.subscribe((data) => {
      if (data) {
        payPackage.value = data;
      }
    });

    headerService.ActivityConfig.subscribe((data) => {
      if (data) {
        activityConfig.value = data;
      }
    })

    /**
     * @description 开始活动倒计时
     */
    function startCountTime(endTime: number, nowTime: number) {
      clearInterval(viewModel.countTimer)
      let expiresTime = endTime - nowTime

      againAssignCountTime(expiresTime--)

      viewModel.countTimer = setInterval(() => {
        if (expiresTime >= 0) {
          againAssignCountTime(expiresTime--)
        } else {
          viewModel.coutTimeNum.end = true
          clearInterval(viewModel.countTimer)
          const endTime = new Date().getTime() / 1000 + 5 * 60
          startCountTime(endTime, new Date().getTime() / 1000)
        }
      }, 1000)
    }

    /**
     * @description 解析时分秒
     **/
    function analysisTime(expiresTime: number) {
      const dealNum = num => (num >= 10 ? num : `0${num}`)
      const h = dealNum(Math.floor(expiresTime / 3600))
      const m = dealNum(Math.floor((expiresTime - h * 3600) / 60))
      const s = dealNum(Math.floor(expiresTime - h * 3600 - m * 60))

      return { h, m, s }
    }

    /**
     * @description 倒计时重新赋值
     */
    function againAssignCountTime(time: number) {
      viewModel.coutTimeNum = { ...viewModel.coutTimeNum, ...analysisTime(time) }
    }

    function handleClick() {
      headerService.reportRetainDialogClose()
      window.close()
    }

    /** 改变支付方式 */
    function changePayment(payment: Payment) {
      selectedPayment.value = payment
      initPayQrCode()
    }

    /**
     * @description 初始化支付二维码
     */
    function initPayQrCode() {
      if (selectedPayment.value.type === PAY_TYPE.WX) {
        loadWeChatPayQrCode()
      } else {
        loadAliPayQrCode()
      }
    }

    /**
     * @description 加载微信支付二维码
     */
    async function loadWeChatPayQrCode() {
      let ocpc_id
      try {
        const { logidUrl } = await bridge.getOcpcUrl()
        ocpc_id = logidUrl
      } catch (error) {
        console.log('=======调起ocpc失败=====');
      }
      const params = {
        payapp: cookie.productId,
        product_id: payPackage.value?.product_id,
        client_extra: {
          ...service.client_extra, // TODO: 二期order_type会变多，一期先写死
          ocpc_id,
          show_from: show_from.value
        }
      }

      service.requestWeChatPayQrcode(params).then(res => {
        handleNormalQrcodeSuccess(res.wx_resp.code_url, res.order_id)
      }).catch(() => {
        payQrCodeLoadFail()
      })
    }

    /**
     * @description 加载支付宝支付二维码
     */
    async function loadAliPayQrCode() {
      let ocpc_id
      try {
        const { logidUrl } = await bridge.getOcpcUrl()
        ocpc_id = logidUrl
      } catch (error) {
        console.log('=======调起ocpc失败=====');
      }
      const params = {
        payapp: cookie.productId,
        product_id: payPackage.value?.product_id,
        return_url: '',
        client_extra: {
          ...service.client_extra, // TODO: 二期order_type会变多，一期先写死
          ocpc_id,
          show_from: show_from.value
        }
      }

      service.requestAliPayQrcode(params).then(res => {
        handleNormalQrcodeSuccess(res?.ali_resp?.code_url, res?.order_id)
      }).catch(() => {
        payQrCodeLoadFail()
      })
    }

    /**
     * @description 处理成功的普通二维码
     */
    function handleNormalQrcodeSuccess(url: string, orderId: string) {
      viewModel.isExpires = false
      viewModel.qrText = url
      viewModel.orderId = orderId

      payOrderLoopAction()
    }

    /**
     * @description 支付二维码加载失败
     */
    function payQrCodeLoadFail() {
      toast.showToast({ message: '获取支付信息异常' })
    }

    /**
     * @description 点击默认二维码图片
     */
    function clickDefaultQrImg() {
      // 过期重新刷新支付二维码
      if (viewModel.isExpires) {
        initPayQrCode()
        return
      }

      bridge.userLogin()
    }

    /**
     * @description 订单轮询操作
     */
    function payOrderLoopAction() {
      clearLoopOrderTimer()

      let totalTime = 3600 // 轮询时长为1小时
      const time = 3 // 每隔3秒轮询一次

      viewModel.loopPayResultTimer = setInterval(() => {
        totalTime = totalTime - time

        if (totalTime > 0) {
          const params = {
            order_id: viewModel.orderId,
            payapp: cookie.productId
          }

          service.requestPayResult(params).then(res => {
            if (isDestroy) {
              clearLoopOrderTimer()
              return
            }

            if (res.order.status === 'paid') {
              handlePaySuccess()
            }
          })
        } else {
          clearLoopOrderTimer()
          viewModel.isExpires = true
        }
      }, time * 1000)
    }

    /**
     * @description 处理支付成功
     */
    async function handlePaySuccess() {
      clearLoopOrderTimer()
      bridge.refreshUserInfo() // 通知客户端刷新用户信息，用于同步客户端用户状态
      await user.requestUserInfo() // 更新前端用户信息
      toast.showToast({ message: '支付成功' })
      emit('pay-success')
    }

    /**
     * @description 清除轮询订单定时器标识
     */
    function clearLoopOrderTimer() {
      clearInterval(viewModel.loopPayResultTimer)
    }

    onBeforeUnmount(() => {
      isDestroy = true
      clearLoopOrderTimer()
    })

    onMounted(() => {
      /** 开始倒计时 */
      const endTime = new Date().getTime() / 1000 + 15 * 60
      startCountTime(endTime, new Date().getTime() / 1000)
      initPayQrCode()
      headerService.reportRetainDialogShow()
    })

    return { viewModel, defaultPayment, selectedPayment, payments, changePayment, clickDefaultQrImg, handleClick, payPackage, activityConfig }
  }

});
