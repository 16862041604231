import { createApp } from 'vue'
import router from './router'
import App from './app.vue'
import Toast from './components/toast/toast'
import 'normalize.css'
import { Provider, reflectiveInjectorPlugin, ReflectiveInjector, NullInjector } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'
import { HttpInterceptorService } from './services/http-interceptor.service'
import { UserService } from './services/user.service';
import { BridgeService } from './services/bridge.service';
import { CookieService } from './services/cookie.service';
import { ToastService } from '@/services/toast.service';
import { ReportService } from '@/services/report.service';
import {ConfigService, product_id_info } from '@/services/config.service';
import { CommonService } from '@/services/common.service';
import {InterestsService} from '@/views/interests/interests.service';
import {CouputerHelperService} from '@/services/product/couputer-helper.service';
import {KantuService} from '@/services/product/kantu.service';
import {PdfService} from '@/services/product/pdf.service';
import {VideoService} from '@/services/product/video.service';
import { PdfConvertService } from '@/services/product/pdf-convert.service';
import {
  KoutuEditorHttpService,
  KantuHttp
} from '@/views/custom-made/kantu/koutu-editor/koutu-editor-http.service'
import { OcpcService } from '@/services/ocpc.service'

const isDebug = process.env.STAGE === 'test' || process.env.NODE_ENV === 'development'
if (isDebug) {
  const eruda = require('eruda/eruda.js')
  eruda.init()
}

const providers: Provider[] = [
  HttpInterceptorService,
  KoutuEditorHttpService,
  {
    provide: Http,
    useFactory (configService: ConfigService, httpInterceptor: HttpInterceptorService) {
      return new Http({ baseURL: configService.getRequestUrl }, httpInterceptor)
    },
    deps: [ConfigService, HttpInterceptorService]
  },
  {
    provide: KantuHttp,
    useFactory (httpInterceptor: KoutuEditorHttpService) {
      const baseURL = isDebug ? 'http://fastpic.aix-test-k8s.iweikan.cn/' : 'https://fastpic.zhhainiao.com/'

      return new Http({ baseURL }, httpInterceptor)
    },
    deps: [KoutuEditorHttpService]
  },
  OcpcService,
  UserService,
  BridgeService,
  CookieService,
  ToastService,
  CommonService,
  ReportService,
  InterestsService,
  {
    provide: ConfigService,
    useFactory(cookie: CookieService) {
      // 可牛电脑助手
      if (cookie.productId === String(product_id_info.couputerHelper)) {
        return new CouputerHelperService()
      }

      // 可牛看图
      if (cookie.productId === String(product_id_info.kantu)) {
        return new KantuService()
      }

      // 可牛pdf
      if (cookie.productId === String(product_id_info.pdf)) {
        return new PdfService()
      }

      // 可牛视频转换器
      if (cookie.productId === String(product_id_info.video)) {
        return new VideoService()
      }

      // PDF转换器阉割版
      if (cookie.productId === String(product_id_info.pdfConvert)) {
        return new PdfConvertService()
      }

      throw new Error('请检查产品id是否正确')
    },
    deps: [CookieService]
  }
]

const rootInjector = new ReflectiveInjector(new NullInjector(), providers)
const bridge = rootInjector.get(BridgeService)

bridge.getTid().finally(() => {
  createApp(App)
    .use(reflectiveInjectorPlugin, rootInjector)
    .use(router)
    .use(Toast)
    .mount('#app')
})
