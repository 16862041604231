import {Injectable} from '@tanbo/vue-di-plugin'
import {ReportService} from '@/services/report.service'
import {CookieService} from '@/services/cookie.service';
import { UserService } from '@/services/user.service'
import { timeToDay } from '@/utils/timeToDay';

@Injectable()
export class HomeService {
  reportVersion = 3; /** 埋点版本 */

  report = this.reporter.makeReporter('infockeniu_vip_paypage:239 product_id:int version:byte vip_version:int show_from:string fun_from:string days:int islogin:int current_uuid:string', {
    show_from: this.cookie.show_from,
    fun_from: this.cookie.fun_from,
    version: this.reportVersion, /** 埋点版本 */
    days: timeToDay(this.cookie.installTime), /** 安装时间 */
    islogin: this.user.isLogin ? 1 : 2, /** 登录状态 */
    current_uuid: this.cookie.svrid, // svrid
  })

  constructor(
    private reporter: ReportService,
    private cookie: CookieService,
    private user: UserService
  ) {
  }
}
