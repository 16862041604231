import { Injectable } from '@tanbo/vue-di-plugin';
import { HttpInterceptor } from '@zhfe/common-library';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import HmacMD5 from 'crypto-js/hmac-md5'
import Hex from 'crypto-js/enc-hex'
import { CookieService } from './cookie.service';
import {BridgeService} from './bridge.service';
import {ToastService} from '@/services/toast.service';
import { ConfigService } from '@/services/config.service';

export class ResponseError extends Error {
  name = 'ResponseError'

  constructor(public responseCommon: any, public requestUrl: string, msg?: string) {
    super(msg)
  }
}

export interface CommonRequestFields {
  app_id?: string /** 应用的id(相当于毒霸的pid) */
  open_id?: string /** (兼容字段)同uid, 保留只是为了兼容客户端请求 */
  token?: string /** 用户token */
  server_id?: string /** (兼容字段)同device_id */
  uuid?: string /** 设备uuid */
  tid1?: number /** tid1 (tid tod 主要用于买量的标识) */
  tod1?: number
  tid2?: number
  tod2?: number
  vip_version?: string /** 客户端版本 */
  tryno?: string /** 渠道号 */
  spid?: number /** 子产品id */
  function_id?: number /** 产品功能id */
  platform?: string /** 请求端的平台（"pc", "android", "ios"） */
  lang?: string /** 语言 */
  install_time?: string /** 安装时间 */
  uid?: string /** 可牛看图唯一id */
}

@Injectable()
export class HttpInterceptorService implements HttpInterceptor<any> {
  /** 使用HMAC-md5 对 request body 签名, 使用的 key 根据 X-Cf-Productid 头部字段而定，获得该摘要的 hex 形式小写字符串 */
  signature = body => HmacMD5(body, this.config.getProductInfo.secret).toString(Hex)

  /** 状态码集合 */
  private retObj = {
    success: 0,
    tokenInvalid: 4001002
  }

  constructor(
    private cookie: CookieService,
    private bridge: BridgeService,
    private toast: ToastService,
    private config: ConfigService
  ) {

  }

  private commonRequestFields: CommonRequestFields = {
    app_id: this.cookie.productId,
    token: this.cookie.getToken,
    open_id: this.cookie.getOpenId,
    server_id: this.cookie.svrid,
    uuid: this.cookie.uuid,
    tid1: this.bridge.tidInfo.tid1,
    tid2: this.bridge.tidInfo.tid2,
    tod1: this.bridge.tidInfo.tod1,
    tod2: this.bridge.tidInfo.tod2,
    vip_version: this.cookie.vipVersion,
    tryno: this.cookie.tryno,
    spid: this.bridge.tidInfo.subpid,
    function_id: this.bridge.tidInfo.fid,
    platform: 'pc',
    lang: 'zh_CN',
    install_time: this.cookie.installTime
  }

  request(request: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> {
    request.data.common = {
      ...this.commonRequestFields,
      token: this.cookie.getToken,
      open_id: this.cookie.getOpenId,
      ...request.data.common
    }

    request.headers.common = {
      'X-Cf-Appid': this.cookie.productId,
      'X-Cf-Authorization': this.signature(JSON.stringify(request.data)),
      'X-Cf-Productid': this.cookie.productId
    }

    return request
  }

  response(response: AxiosResponse): Promise<any> | any {
    const body = response.data
    body.resp_common = body.resp_common || {}
    const { ret } = body.resp_common
    const { success, tokenInvalid } = this.retObj

    if (ret === success) {
      return HttpInterceptorService.handleUrlData(response)
    } else if (ret === tokenInvalid) {
      this.bridge.exit()
      this.cookie.removeUserInfoLongTerm()
      this.toast.showToast({ message: '登录信息已过期，请重新登录' })
      this.bridge.refreshPage()
    }

    return Promise.reject(new ResponseError(body.resp_common, response.config.url as string, body.resp_common?.msg || '未知错误！'))
  }

  /**
   * @description 返回数据处理 (服务端公共库实现跟项目内部实现不一致，故做兼容)
   */
  private static handleUrlData (response: AxiosResponse) {
    const { data: result, config } = response

    const needAddData = [
      '/baoquvip/payv2/wx/native', // 微信支付
      '/baoquvip/payv2/ali/native', // 支付宝支付
      '/baoquvip/payv2/order/query', // 订单结果
      '/baoquvip/payv2/wx/jsapi', // 微信JSAPI支付 jsapi
      '/cutout/get_authorized_code', // 微信JSAPI支付 jsapi
      '/cutout/get_permission_use_cert', // 微信JSAPI支付 jsapi
    ]

    if (needAddData.includes(config.url)) {
      return result
    }

    return result.data
  }
}
