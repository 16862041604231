
import { defineComponent } from 'vue';
import { useReflectiveInjector } from '@tanbo/vue-di-plugin';
import { UserService } from '@/services/user.service';

/** 更多会员信息 */
export default defineComponent({
  setup(_, { emit }) {
    const injector = useReflectiveInjector()
    const user = injector.get(UserService)
    const formatDateVipInfo = user.formatDateVipInfo

    const viewModel = {
      formatDateVipInfo: formatDateVipInfo.map(i => ({ ...i })),
    }

    /**
     * @description 关闭更多会员信息
     */
    function closeMoreMember () {
      emit('close')
    }

    return {
      viewModel,
      closeMoreMember
    }
  }
})
