import { BridgeService } from '@/services/bridge.service'
import { CookieService } from '@/services/cookie.service'
import { ReportService } from '@/services/report.service'
import { UserService } from '@/services/user.service'
import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'
import { BehaviorSubject, Observable } from 'rxjs'

export interface CouponRequestParams {
  /** 优惠券配置id(具体细看apollo的coupon配置) */
  group_coupon_conf_id: string
}

export interface CouponPageDetail {
  /** 优惠卷的价格 */
  discount: string
  /** 优惠券名字 */
  name: string
}

export interface Coupon {
  /** 使用了优惠券的套餐id */
  coupon_product_id: string
  /** 不使用优惠券的套餐id */
  product_id: string
  page_detail: CouponPageDetail
}

export interface CouponResponseData {
  /** 使用优惠券的套餐组(如果没有对应配置或者tryno不匹配，则数据为空) */
  coupon_group: string
  /** 没有使用优惠券的套餐组(如果没有对应配置或者tryno不匹配，则数据为空) */
  no_coupon_group: string
  /** 优惠券配置(如果没有对应配置或者tryno不匹配，则数据为空) */
  coupon_list: Coupon[]
}

@Injectable()
export class CouponService {
  couponConfig: Observable<CouponResponseData>;

  private cacheCouponConfig: CouponResponseData | undefined = undefined;

  private changeCouponConfig = new BehaviorSubject<CouponResponseData>(this.cacheCouponConfig)

  couponList: Observable<Coupon[]>;

  private cacheCouponList: Coupon[] = [];

  private changeCouponList = new BehaviorSubject<Coupon[]>(this.cacheCouponList)

  /** 上报参数 */
  client_extra = {
    version: this.reporter.report_version, /** 埋点版本号 */
    vip_version: this.cookie.vipVersion, /** 会员客户端版本 */
    show_from: this.cookie.show_from, /** 会员页展示来源 */
    fun_from: this.cookie.fun_from, /** 权益来源 */
    vip_status: this.user.isVip ? '1' : '2', /** 是否会员 1：是，2：否 */
    current_uuid: this.cookie.uuid, /** 产品的uuid */
    current_tryno: this.cookie.tryno, /** 产品的tryno */
    current_svrid: this.cookie.svrid, /** 产品的svrid */
    tid1: String(this.bridge.tidInfo.tid1), /** 主通道号（内通道号） */
    tid2: String(this.bridge.tidInfo.tid2), /** 副通道号（内通道号） */
    tod1: String(this.bridge.tidInfo.tod1), /** 主通道号（外通道号） */
    tod2: String(this.bridge.tidInfo.tod2), /** 副通道号（外通道号） */
    installtime: this.cookie.installTime, /** 安装时间 */
    product_id: this.cookie.productId
  }

  constructor(
    private http: Http,
    private cookie: CookieService,
    private bridge: BridgeService,
    private user: UserService,
    private reporter: ReportService
  ) {
    this.couponConfig = this.changeCouponConfig.asObservable()
    this.couponList = this.changeCouponList.asObservable()
  }

  /**
   * 获取优惠券配置
   */
  getCouponConfig (params: CouponRequestParams) {
    return this.http.post<CouponResponseData>('/coupon/group_conf', params).then(res => {
      const { resp_common, ...data} = res
      this.updateCouponConfig(data)
      return data
    }).catch((e) => {
      console.log('getCouponConfig error:', e);
    })
  }

  updateCouponConfig (data: CouponResponseData) {
    this.cacheCouponConfig = data
    this.changeCouponConfig.next(data)
    this.updateCouponList(data.coupon_list)
  }

  updateCouponList (data: Coupon[]) {
    this.cacheCouponList = data
    this.changeCouponList.next(data)
  }

  sortCouponList(productIds: string[]) {
    const productIdIndexMap = new Map<string, number>();
    productIds.forEach((productId, index) => {
      productIdIndexMap.set(productId, index);
    });
    const list = this.cacheCouponList.sort((a, b) => {
      const indexA = productIdIndexMap.get(a.product_id);
      const indexB = productIdIndexMap.get(b.product_id);

      // 如果 product_id 在 productIds 中不存在，则将其排在后面
      if (indexA === undefined) {
        return 1;
      }
      if (indexB === undefined) {
        return -1;
      }

      // 根据 productIds 的顺序进行比较
      return indexA - indexB;
    });
    this.updateCouponList(list)
  }

  couponReporter = this.reporter.makeReporter('infockeniu_vipcoupon_clicknew:234 show_from:int fun_from:int coupon_mode:byte taocan_money:int product_id:int', {
    show_from: Number(this.client_extra.show_from),
    fun_from: Number(this.client_extra.fun_from),
    coupon_mode: 0,
    taocan_money: 0,
    product_id: Number(this.client_extra.product_id)
  })

  /** 上报优惠券展示 */
  reportCouponShow () {
    this.couponReporter({
      coupon_mode: 3,
    })
  }

  /** 上报优惠券点击使用 */
  reportCouponUse (price: number) {
    this.couponReporter({
      coupon_mode: 1,
      taocan_money: price
    })
  }

  /** 上报优惠券点击取消 */
  reportCouponCancel (price: number) {
    this.couponReporter({
      coupon_mode: 2,
      taocan_money: price
    })
  }
}
