<template>
  <div class="member_interest_detail">
    <div class="mid_head native-drag flex_space_center">
      <div class="head_left flex_start_center">
        <img :src="viewModel.logo" class="logo_icon" alt="" />
        <span>会员权益详情</span>
      </div>
      <i class="btn_close native-nodrag" @click="clickClose" />
    </div>
    <div class="mic_content">
      <img :src="viewModel.imgUrlObj.head" />
      <div class="mic_scroll">
        <img :src="viewModel.imgUrlObj.content" />
      </div>
    </div>
  </div>
</template>

<script>
// 会员权益详情

import {reactive} from 'vue';
import {useReflectiveInjector} from '@tanbo/vue-di-plugin'
import {ConfigService} from '../../services/config.service';

export default {
  name: 'MemberInterestDetail',
  setup() {
    const injector = useReflectiveInjector()
    const config = injector.get(ConfigService)
    const viewModel = reactive({
      logo: config.getLogo,
      imgUrlObj: config.getMemberInterestImgObj
    })

    function clickClose () {
      window.close()
    }

    return {
      viewModel,
      clickClose
    }
  }
}
</script>

<style lang="scss" scoped>
.member_interest_detail {
  @include wh(675px, 540px);
  .mid_head {
    height: 32px;
    line-height: 32px;
    background: #FF8C59;
    span {
      font-size: 12px;
      color: #FFFFFF;
    }
  }

  .logo_icon {
    margin: 0 7px;
    @include wh(16px, 16px);
  }
  .btn_close {
    background: url("~@/assets/images/close.png") no-repeat;
    @include wh(32px, 32px);
    @include bg_position();
  }
  .mic_content {
    padding: 14px 0 14px 14px;
     @include wh(660px, 470px);
      
    .mic_scroll {
      height: 384px;
     overflow-y: scroll;
      position: relative;
      top: -5px;
    }
  }
}
</style>
