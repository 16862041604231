import { BridgeService } from '@/services/bridge.service';
import { product_id_info } from '@/services/config.service';
import { CookieService } from '@/services/cookie.service';
import { ReportService } from '@/services/report.service';
import { UserService } from '@/services/user.service';
import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ActivityConfig {
  product_id: number;
  display_txt: string;
}

export interface ActivityConfigResponseData {
  detail: ActivityConfig;
}

export interface PageDetail {
  /** 底部文案 */
  bottom?: string;
  /** 底部价格文案样式 0:不展示 1:展示文字 2: 文字加删除线 */
  bottom_type?: number;
  /** 现价(元) */
  current_fee?: number;
  /** 折扣文案 */
  discount_desc?: string;
  /** 优惠多少钱(元) */
  discount_fee?: number;
  /** 是否终身的套餐展示 */
  is_diamond?: boolean;
  /** 原价(元) */
  origin_fee?: number;
  /** selected_type: 0 # 选中套餐后右下角的显示，0:不显示 1: 签约勾选套餐 2: 钻石/超级会员描述 */
  selected_type?: number;
  /** # 角标文字(title_type=1) */
  title?: string;
  /** 套餐倒计时结束时间戳，用于倒计时，没有则为0(title_type=3) */
  title_end_time?: number;
  /** 角标图片(title_type=2) */
  title_img?: string;
  /** 套餐倒计时开始时间戳，用于倒计时，没有则为0(title_type=3) */
  title_start_time?: number;
  /** 角标展示形式 0:不展示 1:展示文字 2:展示图片 3:展示倒计时 */
  title_type?: number;
  /** 服务于超级会员页，用于展示中间价格旁边的带划线的价格 */
  center_fee?: number;
}

export interface PayPackage {
  /** 套餐id */
  product_id: string;
  /** 是否为签约套餐场景(true:则返回包含签约的套餐组 false:则返回不含签约的套餐组) */
  is_continuous: boolean;
  /** 页面展示信息(透传apollo配置), 具体定义见apix备注 */
  page_detail?: PageDetail;
}

export interface PayPackageResponseData {
  /** 支付套餐 */
  pay_packages: PayPackage;
  /** 服务端时间戳 */
  server_timestamp_ms: string;
}

@Injectable()
export class HeaderService {
  ActivityConfig: Observable<ActivityConfig>;
  private cacheActivityConfig: ActivityConfig | undefined = undefined;
  private changeActivityConfig = new BehaviorSubject<ActivityConfig>(
    this.cacheActivityConfig
  );

  payPackage: Observable<PayPackage>;
  private cachePayPackage: PayPackage | undefined = undefined;
  private changePayPackage = new BehaviorSubject<PayPackage>(
    this.cachePayPackage
  );

  openRetainDialogDate: Observable<string>;
  private cacheOpenRetainDialogDate: string | undefined = localStorage.getItem(
    this.cookie.productId + '_OPEN_RETAIN_DIALOG_DATE'
  );

  private changeOpenRetainDialogDate = new BehaviorSubject<string>(
    this.cacheOpenRetainDialogDate
  );

  /** 上报参数 */
  client_extra = {
    version: this.reporter.report_version, /** 埋点版本号 */
    vip_version: this.cookie.vipVersion, /** 会员客户端版本 */
    show_from: this.cookie.show_from, /** 会员页展示来源 */
    fun_from: this.cookie.fun_from, /** 权益来源 */
    vip_status: this.user.isVip ? '1' : '2', /** 是否会员 1：是，2：否 */
    current_uuid: this.cookie.uuid, /** 产品的uuid */
    current_tryno: this.cookie.tryno, /** 产品的tryno */
    current_svrid: this.cookie.svrid, /** 产品的svrid */
    tid1: String(this.bridge.tidInfo.tid1), /** 主通道号（内通道号） */
    tid2: String(this.bridge.tidInfo.tid2), /** 副通道号（内通道号） */
    tod1: String(this.bridge.tidInfo.tod1), /** 主通道号（外通道号） */
    tod2: String(this.bridge.tidInfo.tod2), /** 副通道号（外通道号） */
    installtime: this.cookie.installTime, /** 安装时间 */
    product_id: this.cookie.productId,
  };

  constructor(
    private http: Http,
    private cookie: CookieService,
    private bridge: BridgeService,
    private user: UserService,
    private reporter: ReportService
  ) {
    this.ActivityConfig = this.changeActivityConfig.asObservable();
    this.payPackage = this.changePayPackage.asObservable();
    this.openRetainDialogDate = this.changeOpenRetainDialogDate.asObservable();
  }

  updateActivityConfig(data: ActivityConfig) {
    this.cacheActivityConfig = data;
    this.changeActivityConfig.next(data);
  }

  /**
   * 获取活动配置
   * @param name 活动名
   * @returns
   */
  getActivityConfig(name: string) {
    console.log('=============activity config');

    return this.http.post<ActivityConfigResponseData>('/activity/config', {
      name,
    }).then((res) => {
      this.updateActivityConfig(res.detail);
      this.getPayPackageDetail(String(res.detail.product_id));
      return res;
    });
  }

  updatePayPackage(data: PayPackage) {
    this.cachePayPackage = data;
    this.changePayPackage.next(data);
  }

  getPayPackageDetail(productId: string) {
    return this.http.post<PayPackageResponseData>(
      '/paypackagebase/get_pay_package',
      {
        product_id: productId,
      }
    ).then((res) => {
      this.updatePayPackage(res.pay_packages);
      return res;
    });
  }

  updateOpenRetainDialogDate(data: string) {
    localStorage.setItem(this.cookie.productId + '_OPEN_RETAIN_DIALOG_DATE', data);
    this.cacheOpenRetainDialogDate = data;
    this.changeOpenRetainDialogDate.next(data);
  }

  dialogReporter = this.reporter.makeReporter('infockeniu_vip_adpaopao:238 adshow:byte adclick:byte adtype:int islogin:byte ver:byte current_uuid:string', {
    adshow: 0,
    adclick: 0,
    adtype: this.client_extra.product_id === String(product_id_info.pdfConvert) ? 1 : 2,
    islogin: 1,
    ver: 1,
    current_uuid: this.cookie.svrid,
  })

  /** 上报挽留弹窗展示 */
  reportRetainDialogShow () {
    this.dialogReporter({
      adshow: 1,
    })
  }

  /** 上报挽留弹窗关闭 */
  reportRetainDialogClose () {
    this.dialogReporter({
      adclick: 1,
    })
  }
}
