<template>
  <div class="receive_member">
    <div class="rm_head flex_space_center native-drag">
      <div class="rmh_left flex_start_center">
        <img :src="viewModel.logo" alt="logo">
        <span>{{ viewModel.title }}</span>
      </div>
      <i @click="rmClose" class="rm_close native-nodrag" />
    </div>
    <div class="rm_content">
      <div class="rmc_one flex_start_center">
        <i class="member_icon" />
        <div>领取成功！快去使用您的会员权益吧~</div>
      </div>
      <div class="rmc_two flex_end_center">
        <span @click="rmOk">好的</span>
      </div>
    </div>
  </div>
</template>

<script>

// 可牛电脑助手 - 领取会员成功组件
import {onMounted, reactive} from 'vue';
import {useReflectiveInjector} from '@tanbo/vue-di-plugin';
import { ReceiveMemberService } from './receive-member.service';
import { ConfigService } from '../../../services/config.service';

export default {
  name: 'ReceiveMember',
  setup () {
    const injector = useReflectiveInjector([ReceiveMemberService])
    const service = injector.get(ReceiveMemberService)
    const config = injector.get(ConfigService)
    const viewModel = reactive({
      logo: config.getLogo,
      title: config.getTitle
    })

    onMounted(() => {
      service.report({ nshow: 1 })
    })

    function rmClose () {
      service.report({ click: 2 })
      window.close()
    }

    function rmOk () {
      service.report({ click: 1 })
      window.close()
    }

    return {
      viewModel,
      rmOk,
      rmClose
    }
  }
}
</script>

<style lang="scss" scoped>
.receive_member {
  width: 360px;
  height: 180px;
  background: #FFFFFF;
  border-radius: 6px;
  .rm_head {
    width: 100%;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #E7E7EB;
    .rmh_left {
      img {
        @include wh(16px, 16px);
        margin: 0 6px 0 8px;
      }
      span {
        font-size: 12px;
        color: #333333;
        line-height: 16px;
      }
    }
    .rm_close {
      @include wh(20px, 20px);
      background: url("./_images/rmh_close.png");
      @include bg_position();
      margin-right: 8px;
    }
  }
  .rm_content {
    .rmc_one {
      margin: 20px 0;
      .member_icon {
        @include wh(48px, 48px);
        background: url("./_images/rmh_member.png");
        margin: 0px 16px 0 30px;
      }
      & > div {
        width: 235px;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
      }
    }
    .rmc_two {
      & > span {
        width: 100px;
        height: 36px;
        line-height: 36px;
        border-radius: 18px;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
        margin-right: 20px;
        transition: background-color .1s linear;
        @include bg_tristate(#47C238, #56E445, #1FA60F);
        cursor: pointer;
      }
    }
  }
}
</style>
