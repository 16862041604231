
import { defineComponent, onMounted } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { UserInfoService } from '@/views/user-info/user-info.service'
import { HomeService } from '@/views/home/home.service'
import { TabService } from '@/views/home/_components/tab.service'
import Pay from '@/views/pay/pay.vue'
import Header from '@/components/header/header.vue'
import UserInfo from '@/views/user-info/user-info.vue'
import Interests from '@/views/interests/interests.vue'

/** 智能抠图 - 次数购买会员中心 */
export default defineComponent({
  name: 'Home',
  components: {
    Pay,
    UserInfo,
    Interests,
    Header,
  },
  setup() {
    const injector = useReflectiveInjector([HomeService, UserInfoService, TabService])
    const service = injector.get(HomeService)

    onMounted(() => {
      service.report()
    })
  }
})
