<template>
  <div class="interest-and-comment">
    <div class="tabs">
      <div class="tab-nav-item" :class="{ active: viewModel.curTab === 0 }" @click="changeTab(0)">{{ tabTitle }}</div>
      <div class="tab-nav-item" :class="{ active: viewModel.curTab === 1 }" @click="changeTab(1)">会员评价(100+)</div>
    </div>
    <div class="show-module">
      <Interests
        v-if="viewModel.curTab === 0"
      />
      <Comment
        v-else
        :user-obj="userObj"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, computed } from 'vue'
import Interests from './interests.vue'
import Comment from './_components/CommentModule.vue'
import { commentABTestEnum as AbTestEnum } from '../../services/user.service'

// tab标题
const TAB_TITLE = {
  KENIU: '可牛PDF会员权益',

}
export default defineComponent({
  name: 'InterestAndComment',
  components: {
    Interests,
    Comment
  },
  props: {
    // 评论模块AB测参数
    showComment: {
      type: String,
      default: AbTestEnum.BTest
    },
    userObj: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props) {
    const viewModel = reactive({
      curTab: props.showComment == AbTestEnum.BTest ? 1 : 0,
    })

    // tab标题
    const tabTitle = computed(() => {
      return TAB_TITLE.KENIU
    })

    // tab改变
    const changeTab = (flag) => {
      viewModel.curTab = flag
    }

    return {
      viewModel,
      tabTitle,
      changeTab
    }
  }
})
</script>

<style scoped lang="scss">
.interest-and-comment {
  margin-top: 22px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-family: "Microsoft YaHei";

  .tabs {
    display: flex;
    justify-content: center;
    height: 33px;

    .tab-nav-item {
      position: relative;
      width: 180px;
      text-align: center;
      line-height: 30px;
      border-bottom: 1px solid #E2E2E2;
      font-size: 16px;
      color: #666;
      cursor: pointer;
      &.active {
        color: #995A00;
        font-weight: 600;
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 3px;
          background-color: #FE4E15;
        }
      }
    }
  }
}
</style>
