<template>
  <div
    v-for="(pItem, pIdx) in viewModel.list"
    :key="pIdx"
    :class="['spread-item', `scroll_${pItem.key}`]"
  >
    <div :class="['item-content flex_column_center_start', pIdx % 2 === 0 ? 'left' : 'right']">
      <div>
        <span class="title">{{ pItem.page_detail?.title }}</span>
        <ul class="desc-list">
          <li
            v-for="(cItem, cIdx) in pItem.page_detail?.desc_list"
            :key="cIdx"
          >
            {{ cItem }}
          </li>
        </ul>
        <slot name="btn" :item="pItem" />
      </div>
    </div>
    <div :class="['item-pic', pIdx % 2 === 0 ? 'right' : 'left']">
      <img :src="pItem.page_detail?.img_url">
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from 'vue'
export default defineComponent({
  name: 'InterestDescList',
  props: {
    list: {
      type: Array,
      default: () => ([])
    },
  },

  setup (props) {
    const viewModel = reactive({
      list: props.list
    })

    return {
      viewModel
    }
  }
})

</script>

<style lang="scss" scoped>
.spread-item {
  height: 260px;
  position: relative;
  .item-content {
    height: 200px;
    font-size: 16px;
  }
  .item-content.left {
    left: 36px;
  }
  .item-content.right {
    left: 404px;
  }
  .left {
    position: absolute;
    left: 0;
    top: 0;
  }
  .right {
    position: absolute;
    left: 320px;
    top: 0;
  }
}
.title {
  display: inline-block;
  color: #333333;
  font-size: 28px;
  margin-bottom: 14px;
  height: 37px;
  line-height: 37px;
}
.item-pic {
  width: 360px;
  @include wh(360px, 200px);
  img {
    width: 100%;
    height: 100%;
  }
  &.left {
    position: relative;
    left: 16px;
  }
}
.desc-list {
  width: 280px;
  li {
    position: relative;
    @extend .text_spot;
    font-size: 16px;
    margin-bottom: 4px;
    color: #666666;
    height: 21px;
    line-height: 21px;
    padding-left: 16px;
    box-sizing: border-box;
    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      @include wh(6px, 6px);
      border-radius: 50%;
      background: #797979;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
