
import { defineComponent, onMounted, reactive } from 'vue'
import { createKoutuEditor } from '@gaoding/editor-sdk';
import { useReflectiveInjector } from '@tanbo/vue-di-plugin';
import { KoutueEditorService, save_err_ret } from './koutu-editor.service'
import { UserInfoResponseData, UserService } from '@/services/user.service';
import BuyKoutuNumDialog from './_components/buy-koutu-num-dialog.vue'
import { BridgeService } from '@/services/bridge.service';

export default defineComponent({
  name: 'KoutuEditor',
  components: {
    BuyKoutuNumDialog
  },
  setup () {
    const injector = useReflectiveInjector([KoutueEditorService])
    const service = injector.get(KoutueEditorService)
    const user = injector.get(UserService)
    const bridge = injector.get(BridgeService)

    const viewModel = reactive({
      editor: null,
      /** 看图服务端唯一id */
      uid: '',
      /** 登录状态 */
      isLogin: false,
      isVip: user.isVip,
      /** 文件路径 */
      filepath: '',
      /** 是否拥有抠图权限 */
      isKoutuAuth: false,
      /** 抠图次数购买对话框 */
      buyKoutuNumVisible: false
    })

    /** 订阅用户信息变化 */
    user.onUserInfoChanged.subscribe(() => {
      const box = ['isVip', 'isKoutuAuth']
      box.forEach(e => (viewModel[e] = user[e]))
    })

    onMounted(() => {
      viewModel.editor = createKoutuEditor({
        appId: 'YUCAYB442299',
        
        container: '.template_editor_container',
        // 用户授权
        async authorize() {
          const res = await service.requestAuthorize()
          return Promise.resolve(res.authorized_code)
        },
        // 获取权益使用凭证
        async getUseRightCert(info) {
          service.report({ act: 6 })

          // 有抠图次数即可下载
          if (viewModel.isLogin && viewModel.isKoutuAuth) {
            const params = {
              ability_code: 'KE002',
              works_id: info.workId,
              common: {
                uid: viewModel.uid
              }
            }
            const res = await service.requestGetUseRightCert(params)
            return Promise.resolve(res.permission_use_cert)
          }

          // 会员无抠图次数，打开智能抠图次数购买页
          if (viewModel.isVip && !viewModel.isKoutuAuth) {
            service.report({ act: 8, save_errcode: save_err_ret.vip_noNum as string })
            viewModel.buyKoutuNumVisible = true
            return false
          }

          service.report({ act: 8, save_errcode: save_err_ret.noLogin_noVip as string })
          openMemberCenter()

          return false
        },
      })

      service.report({ act: 2 })

      importImgSource()
      saveImgSource()
      receviceUserStatus()
    })

    /**
     * @description 导入图片资源
     */
    function importImgSource () {
      bridge.uploadImg().then(res => {
        if (res.ret !== 0) return
        
        viewModel.filepath = res.filepath
        const feStr = `data:image/${res.ext};base64,`
        const base64 = feStr + res.data
        
        viewModel.editor.importImage(base64)
      })
    }

    /**
     * @description 保存图片资源
     */
    function saveImgSource () {
      viewModel.editor.onSave(({ files }) => {
        service.report({ act: 7 })
        const file = files[0]
        service.fileByBase64(file, base64 => {
          const str = base64.split('base64,')[1]
          const params = {
            filepath: viewModel.filepath,
            data: str
          }

          bridge.saveImg(params)
        })
      })
    }

    /**
     * @description 接收用户状态 (智能抠图通过客户端通知前端刷新用户信息，拿不到存储的cookie(原因：抠图和vip模块进场隔离))
     */
    function receviceUserStatus () {
      bridge.notifyUserInfoChanged(res => {
        if (res.ret !== 0) return

        const data = JSON.parse(res.data)
        // 登录
        if (data.is_logout === 0) {
          const { token, open_id } = data
          viewModel.uid = open_id
          viewModel.isLogin = true
          const params = {
            common: {
              token,
              open_id,
            }
          }

          user.requestUserInfo(params)
          return
        }

        // 退出
        if (data.is_logout === 1) {
          viewModel.isLogin = false
          user.userInfo = {} as UserInfoResponseData
          user.onUserInfoChanged.next()
        }
      })
    }

    /** 
     * @description 点击更换图片
     */
    function clickReplaceImg() {
      bridge.openKoutuMain()
    }

    /** 
     * @description 点击文件上传按钮
     */
    function replaceImg () {
      bridge.openKoutuMain()
      service.report({ act: 3 })
    }

    /**
     * @description 打开会员中心
     */
    function openMemberCenter () {
      bridge.openMemberCenter()
    }

    return {
      viewModel,
      clickReplaceImg,
      replaceImg
    }
  }
})
