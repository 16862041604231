import { Http } from '@zhfe/common-library'
import { Injectable } from '@tanbo/vue-di-plugin'
import { CookieService } from '@/services/cookie.service'
import { BridgeService } from '@/services/bridge.service'
import { ReportService } from '@/services/report.service'
import {UserService} from '@/services/user.service';
import {CommonService} from '@/services/common.service';
import { ConfigService, product_id_info } from '@/services/config.service'
import { OcpcService, OCPC_ACTION } from '@/services/ocpc.service'
import { timeToDay } from '@/utils/timeToDay'

export enum OcpcType {
  Login = 49,
  Active = 4,
  Buy = 10
}
export interface FastLoginUserInfo {
  /** 用户id */
  open_id: string
  /** 用户姓名 */
  name: string
  /** 用户头像URL */
  avatar: string
  /** 用户性别, 0未知; 1男性; 2女性 */
  sex: number
  /** 登录类型 */
  login_type: number
}

export interface TokenInfo {
  /** token */
  token: string
  /** 下次刷新用户token的时间间隔,单位毫秒 */
  check_time_ms: number
}

export interface FastLoginResponseData {
  /** 用户信息 */
  user_info: FastLoginUserInfo
  /** 登录的token */
  token_info: TokenInfo
}

@Injectable()
export class LoginService {

  reportVersion: number = 3; /** 埋点版本 */

  constructor(
    private http: Http,
    private ocpc: OcpcService,
    private bridge: BridgeService,
    private cookie: CookieService,
    private reporter: ReportService,
    private user: UserService,
    private common: CommonService,
    private config: ConfigService
  ) {
  }

  ocpcreport = this.reporter.makeReporter('duba_jgpdf_sem_ocpc:8167 activation:string signin:string buy:string error:string product_id:string version:byte vip_version:int', {
    activation: '0',
    signin: '0',
    buy: '0',
    error: '0',
  })

  report = this.reporter.makeReporter('infockeniu_login_info:237 product_id:int version:byte vip_version:int login_result:byte reason:string show_time:string current_uuid:string current_tryno:string current_svrid:string login_type:short is_vip:byte show_from:string login_act:short fun_from:string days:int', {
    login_result: 0,
    reason: '',
    show_time: '',
    current_uuid: this.cookie.uuid,
    current_tryno: this.cookie.tryno,
    current_svrid: this.cookie.svrid,
    login_type: 0,
    is_vip: this.user.isVip ? 1 : 2,
    show_from: this.cookie.show_from,
    login_act: 0, /** 操作 1：展示 2：登录结果 */
    fun_from: this.cookie.fun_from,
    version: this.reportVersion, /** 埋点版本 */
    days: timeToDay(this.cookie.installTime), /** 安装时间 */
  })

  /**
   * @description 请求快速登录
   */
  requestQuickLogin (token: string, loginType: number) {
    const params = {
      common: {
        token
      },
      device: { // TODO: 设备信息一期先不做，暂且穿空
        account: '',
        os: ''
      }
    }

    return this.http.post<FastLoginResponseData>('/account/login/token', params).then(res => {
      console.log('token res:', res);

      this.handleLoginSuccess(res, loginType)
    }).catch(err => {
      console.log('token err:', err);
      this.handleLoginFail(err, loginType)
    })
  }

  /**
   * @description 处理登录成功
   */
  async handleLoginSuccess(res: FastLoginResponseData, loginType: number) {
    // 每次刷新页面客户端会根据token拉取用户信息登录状态并设置cookie，这里免设置cookie
    const userInfo = res.user_info
    const { token } = res.token_info
    const { open_id, login_type } = userInfo
    const clientData = { token, open_id, type: login_type }

    this.cookie.setUserInfoLongTerm({ token, open_id }) // 用户信息持久化
    this.cookie.addRecentLoginList({ ...userInfo, token }) // 历史登录持久化

    this.report({
      show_time: this.reportCurrentDate(),
      login_act: 2,
      login_type: loginType, /** 服务端返回缺少快速登录，故做兼容 */
      login_result: 1
    })

    // 103, 111渠道获取ocpc 其他走catch finally
    if (this.cookie.productId === String(product_id_info.pdfConvert) || this.cookie.productId === String(product_id_info.pdf) || this.cookie.productId === String(product_id_info.video)) {
      let ocpc_id
      try {
        const { logidUrl } = await this.bridge.getOcpcUrl()
        ocpc_id = logidUrl
      } catch (error) {
        console.log('=======调起ocpc失败=====');
      }

      if (ocpc_id) {
        Promise.all([
          this.ocpc.reportOcpcAction(ocpc_id, OCPC_ACTION.LOGIN),
          this.handleReceiveMemberAcitivity(() => {
            console.log('领取会员活动成功');
          })]).finally(() => {
          this.afterFunc(clientData)
        })
      } else {
        this.handleReceiveMemberAcitivity(() => this.afterFunc(clientData))
      }
    } else {
      console.log('非103，111渠道');
      this.handleReceiveMemberAcitivity(() => this.afterFunc(clientData)) // 处理活动结果后刷新窗口
    }
  }

  /**
   * @description 之后运行
   */
  afterFunc (data) {
    this.bridge.winClose(data)
    window.close()
  }

  /**
   * @description 处理电脑助手领取会员活动
   */
  async handleReceiveMemberAcitivity (callback) {
    const vip_version = Number(this.cookie.vipVersion)

    try {
      if (this.cookie.productId === '102' && vip_version >= 4) {
        const { result } = await this.bridge.getActivityId()

        if (result === 0) {
          callback()
          return
        }

        // 存在活动标识则调用接口领取7天会员
        this.requestAcitivityReceive({ activity_name: 'pcmgr-7-days' }).then(() => {
          const { origin, search } = location
          const params = {
            url: `${origin}/receiveMember${search}`,
            open_web: 'receiveMember',
            width: 360,
            height: 180
          }

          callback()
          this.bridge.openOptionWindow(params)
        }).catch(() => {
          callback()
        })
      } else {
        callback()
      }
    } catch (error) {
      callback()
    }
  }

  /**
   * @description 处理活动领取
   */
  requestAcitivityReceive (params) {
    return this.http.post('/activity/receive', params)
  }

  /**
   * @description 处理登录失败
   */
  handleLoginFail (err: any, loginType: number) {
    this.report({
      show_time: this.reportCurrentDate(),
      login_act: 2,
      login_result: 2,
      login_type: loginType,
      reason: JSON.stringify(err.responseCommon)
    })
  }

  /** 上报当前时间 */
  reportCurrentDate() {
    return this.common.handleDate(new Date().getTime(), 'yyyy-MM-dd hh:mm:ss')
  }
}
