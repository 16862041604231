import { getUrlParam } from '@shared/utils/base'
import { ConfigService, product_id_info } from '@/services/config.service';

export class VideoService extends ConfigService {
  /** 获取区分场景套餐id */
  get scenePackageId () {
    const sceneName = getUrlParam('scene')
    const scene_info = {
      home: '001',
      wxmc: '010',
    }

    return scene_info[sceneName] ?? '001'
  }

  getTryno= '5600'
  getTitle= '可牛视频转换器'
  wxAppId= 'wx701b6bf77994b435'
  getPackageId= product_id_info.video + this.scenePackageId
  getScenePackageId= this.scenePackageId
  getLogo= require('@/assets/images/video/logo.png')
  getOfficialLoginImg= require('@/assets/images/video/guide_bg.png')
  getHeaderQrCodeImg= require('@/assets/images/video/qrcode_official.png')
  getWebsiteUrl= 'https://www.ijinshan.com/video/index.html'
  getMemberInterestDesc= '提供视频转换、视频剪辑、视频合并、视频水印、音频转换、视频转GIF、视频压缩、视频美化等权益'
  getMemberProtocolLink= 'https://www.keniu.com/privacy/keniukVideoVipLicense.html'
  getMemberInterestImgObj= {
    head: 'https://api-cos.keniu.com/interest/video/vedio_compare_header.png',
    content: 'https://api-cos.keniu.com/interest/video/vedio_compare.png'
  }

  getProductInfo= {
    appid: '105',
    secret: 'dsshkjfwu29u82389f9u2evhf923uv23',
    version: 1
  }

  getMemberTypeInfo= {
    normal: 'video_normal',
    diamond: 'video_diamond'
  }

  getRequestUrl = 'https://api-videovip.keniu.com'
}
