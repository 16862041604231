import {Injectable} from '@tanbo/vue-di-plugin';
import {ReportService} from '@/services/report.service';
import {CookieService} from '@/services/cookie.service';

@Injectable()
export class ReceiveMemberService {
  constructor(
    private reporter: ReportService,
    private cookie: CookieService
  ) {
  }

  report = this.reporter.makeReporter('infockeniu_knzsactivity_pop:182 fun_from:short nshow:byte poptype:short click:short version:short noshowreson:string vip_version:int product_id:int', {
    fun_from: this.cookie.fun_from,
    nshow: 2,
    poptype: 2,
    click: 0,
    noshowreson: ''
  })
}
