import { Injectable } from '@tanbo/vue-di-plugin'
import * as bridge from '@vipsdk/js-bridge'
import { ConfigService } from '@/services/config.service';
import { callNewRequest } from '@/services/callNewRequest'

export interface vipVerionInfo {
  data: {
    retust: number /** 客户端版本号 */
  }
}

export interface KisProcessParams {
  path: string /** 路径 */
  cmd: string /** 命令参数 */
}

export interface AwakenAppParams {
  softname?: string /** 软件名称 */
  cmd?: string /** 调起参数，用于打开软件后调起对应的功能页面 */
}

export interface TidInfo {
  tid1: number
  tid2: number
  tod1: number
  tod2: number
  subpid: number
  fid: number
}

export interface WincloseParams {
  /**  登录成功返回的用户token */
  token: string,
  /**  登录成功返回的用户open_id, */
  open_id: string,
  /**  登录方式；1:微信, 2:QQ, 3:手机登录 */
  type: number,
  /**  是否刷新界面 默认0 刷新 */
  noRefresh?:number,
}

export interface RefreshUserInfoParams {
  /**  用户token */
  token: string,
  /**  用户open_id */
  open_id: string,
}

export interface DeviceInfoResponseData {
  /** 名称？ */
  name: string
  /** 系统名称 */
  os: string
  /** 结果码 */
  result: number
}

export interface isAppInstallParams {
  /** 应用名称 */
  softname: string
}

interface ProductParams {
  /** 产品窗口类型 暂有：login, membercenter 两种 */
  type: string
  /** 产品id */
  id: string
}

interface OpenOptionWindowParams {
  url: string
  /** 页面标识 */
  open_web: string
  width: number
  height: number
}

interface RefreshPageParams {
  /** 类别 include=需要, ignore=不需要 */
  type?: 'include' | 'ignore';
  /** 与客户端协定的页面名称 */
  pages: string[]
}

interface ActivityResponseData {
  /** 结果 1：成功 0：失败 */
  result: number
  /** 产品名称 */
  product_name: string
}

interface SaveImgParams {
  /** 文件路径 */
  filepath: string
  /** 图片base64 */
  data: string
}

interface UploadImgResponseData {
  ret: number
  /** 文件路径 */
  filepath: string
  /** 文件名称 */
  filename: string
  /** 图片base64 */
  data: string
  /** 图片后缀格式 */
  ext: string
}

export enum OLD_ACCOUNT_STATUS {
  SHOW = 1,
  HIDDEN =0
}
interface ShowOldAccountResponseData {
  result: OLD_ACCOUNT_STATUS
}

@Injectable()
export class BridgeService {
  tidInfo = {
    tid1: 0,
    tid2: 0,
    tod1: 0,
    tod2: 0,
    subpid: 0,
    fid: 0
  } as TidInfo

  /** 默认用户参数 */
  private defaultUserParams = {} as WincloseParams

  constructor(
    private config: ConfigService
  ) {
  }

  /** 设置默认用户参数 */
  setDefaultUserParams (params: WincloseParams) {
    this.defaultUserParams = params
  }

  /** 获取客户端公共参数，产品用于区分场景？ */
  async getTid () {
    const t = await bridge.callRequest({ fnName: 'getTid' })
    const f = await bridge.callRequest({ fnName: 'getScenesInfo' })

    this.tidInfo = { ...t, ...f }
  }

  /** 获取客户端版本 */
  getVipVersion (): Promise<vipVerionInfo> {
    return bridge.client_getVipVersion()
  }

  /** 打开闪电查杀等应用 */
  openKisProcess (params: KisProcessParams) {
    return bridge.client_openKisProcess(params)
  }

  /** 退出 */
  exit () {
    return bridge.client_logout()
  }

  /** 刷新页面 */
  refreshPage (params?: RefreshPageParams) {
    return bridge.client_refresh_pages(params)
  }

  /** 刷新用户 */
  refreshUserInfo () {
    const params = {
      open_id: this.defaultUserParams.open_id,
      token: this.defaultUserParams.token,
    } as RefreshUserInfoParams

    return bridge.client_refresh_userinfo(params)
  }

  /** 会员中心窗口登录 */
  userLogin () {
    // TODO:这里跳转链接是客户端控制的，可以传任意值，但不传打不开
    // 会员中心调用登录会走客户端main的一个类，在其他窗口调用进入的是另一个类。 其他窗口调用现象：没反应（客户端深坑呀）
    return bridge.client_open_login_window({ url: '/login' })
  }

  /** 其他窗口登录, 当前登录为其他窗口调不起userLogin而生 */
  otherUserLogin () {
    const { origin, search } = location
    const params = {
      url: `${origin}/login${search}`,
      width: 350,
      height: 470
    }

    return bridge.client_open_login_window(params)
  }

  /** 获取设备的信息 系统名和版本 */
  getDeviceInfo (): Promise<DeviceInfoResponseData> {
    return bridge.client_get_device_info()
  }

  /** 客户端上报 */
  report (table: string, info: any) {
    return bridge.client_report_by_kfmt(table, info)
  }

  /** 通知客户端刷新页面 */
  winClose(params: WincloseParams = this.defaultUserParams) {
    return bridge.client_winclose(params)
  }

  /** 调用接口打开相应的前端界面窗口 */
  openOptionWindow(params: OpenOptionWindowParams) {
    return bridge.client_openOptionWindow(params)
  }

  /** 安装并调起应用 */
  awakenApp (params: AwakenAppParams) {
    return bridge.callRequest({ fnName: 'awakenApp', params })
  }

  /** 判断应用是否安装 */
  isAppInstall (params: isAppInstallParams) {
    return bridge.callRequest({ fnName: 'isAppInstall', params })
  }

  /** 切换不同产品 */
  switchProduct (type: string) {
    const params = {
      type,
      id: this.config.getOldAccountLoginInfo.id
    } as ProductParams

    return bridge.callRequest({ fnName: 'swithProductId', params})
  }

  /** 获取是否含有活动id标识 */
  getActivityId () {
    return bridge.callRequest({ fnName: 'getActivityId' }) as Promise<ActivityResponseData>
  }

  /** 可牛看图 - 上传图片 */
  uploadImg () {
    return bridge.callRequest({ fnName: 'uploadimg' }) as Promise<UploadImgResponseData>
  }

  /** 可牛看图 - 保存图片 */
  saveImg (params: SaveImgParams) {
    return bridge.callRequest({ fnName: 'saveimg', params })
  }

  /** 可牛看图 - 打开抠图主界面 */
  openKoutuMain () {
    return bridge.callRequest({ fnName: 'selectimg' })
  }

  /** 打开会员中心 */
  openMemberCenter () {
    const { origin, search } = location

    this.openOptionWindow({
      url: `${origin}${search}&scene=home`,
      open_web: 'Main',
      width: 700,
      height: 803
    })
  }

  /**
   * 客户端通知退出登录、登录、支付成功等状态（可牛看图-智能抠图使用）
   */
  notifyUserInfoChanged(callback) {
    return registerRequest({ fnName: 'notifyUserInfoChanged', callback })
  }

  getOcpcUrl() {
    return callNewRequest({ fnName: 'getOcpcUrl' })
  }

  isShowOldAccount(): Promise<ShowOldAccountResponseData> {
    return callNewRequest({ fnName: 'callClient', params: { type: 'isShowJGPDF' } })
  }
}

function registerRequest (data) {
  if (window.external) {
    const { fnName, callback } = data || {}
    if (!fnName) throw new Error('Please enter fnname')
    const clientParams = {
      fnName,
      registerFunc: ret => {
        callback(ret)
      },
      onSuccess: ret => {
        console.log('回调成功 ', fnName, '返回值: ', JSON.parse(ret))
      },
      onFailure: (errcode, errmsg) => {
        console.log('回调失败 ', fnName, errcode, errmsg)
      }
    }
    // @ts-ignore
    window.external.RegisterRequest(clientParams)
  } else {
    throw new Error('Current environment does not support')
  }
}
