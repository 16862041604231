<template>
  <div class="comment-module">
    <div class="mark">
      <div class="title">会员满意度：</div>
      <div class="mark-start">
        <div v-for="i in 5" :key="i" class="mark-start-item active">
        </div>
      </div>
      <p class="score">4.95</p>
    </div>

    <div class="comment">
      <div class="comment-item" v-for="(item, index) in viewModel.commentList" :key="index">
        <div class="avatar">
          <img :src="item.avatar" alt="">
        </div>
        <div class="name">{{ item.user_name }}</div>
        <div class="content">{{ item.comment_detail }}</div>
      </div>

      <p class="comment-status">{{ viewModel.commentStatus }}</p>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, onMounted } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { UserService } from '@/services/user.service'

// 评论状态描述
const COMMENT_DESC = {
  PENDING: '数据正在加载中...',
  NO_MORE: '没有更多了',
}
export default defineComponent({
  name: 'CommentModule',
  props: {
    userObj: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props, context) {
    const injector = useReflectiveInjector([UserService])
    const user = injector.get(UserService)

    const viewModel = reactive({
      commentList: [],
      isExistData: true, // 是否还存在分页数据
      lastCommentId: 0, // 当前最后一个评论的ID，作为服务端分页查询数据的游标
      commentStatus: '',
      curPage: 1, // 评论当前页码
    })
    props.userObj.scrollSub.subscribe((event) => {
      handleScroll(event)
    })
    onMounted(() => {
      getCommentInfo()
    })

    // 处理滚动事件
    function handleScroll (e) {
      const event = e.target
      // 网页总高度
      const scrollHeight = event.scrollHeight
      // 滚动条距离顶部距离
      const scrollTop = event.scrollTop
      // 可视窗口高度
      const clientHeight = event.clientHeight

      // 滚动条距离顶部距离 + 可视窗口高度 >= 网页总高度（说明滚动到底部了，进行数据请求）
      if (scrollTop + clientHeight >= scrollHeight) {
        if (!viewModel.isExistData) return
        viewModel.isExistData = false
        viewModel.commentStatus = COMMENT_DESC.PENDING
        viewModel.curPage += 1
        getCommentInfo()
      }
    }

    // 获取评论数据方法封装
    function getCommentInfo () {
      user.requestCommentInfo({
        page: viewModel.curPage,
        page_size: 50,
      }).then(res => {
        viewModel.commentList = [...viewModel.commentList, ...res.comments]
        viewModel.isExistData = res.has_more

        if (!res.has_mode) {
          viewModel.commentStatus = COMMENT_DESC.NO_MORE
        } else {
          viewModel.commentStatus = ''
        }
      })
    }
    return {
      viewModel,
    }
  }
})
</script>

<style scoped lang="scss">
.comment-module {
  margin-top: 15px;
  padding: 0 51px;

  .mark {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    .title {
      color: #333;
      font-size: 14px;
      font-weight: 600;
    }
    .mark-start {
      display: flex;
      .mark-start-item {
        margin-left: 4px;
        width: 20px;
        height: 20px;
        background-image: url('../_images/start-default.png');
        background-size: cover;
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-image: url('../_images/start-active.png');
          background-size: cover;
        }

        &:last-child::after {
          width: calc(0.95 / 1 * 100%);
        }
      }
    }
    .score {
      margin-left: 16px;
      padding-top: 2px;
      font-size: 20px;
      font-weight: 600;
      color: #FFA421;
    }
  }

  .comment {
    .comment-item {
      display: flex;
      align-items: center;
      padding: 16px 0;
      font-size: 14px;
      color: #333;
      border-bottom: 1px solid #F0F0F0;
      &:last-child {
        padding-bottom: unset;
        border-bottom: none;
      }
      .avatar {
        height: 48px;
        width: 48px;
        overflow: hidden;
        border-radius: 50%;
        flex: 0 0 48px;
        background-size: contain;
        img {
          width: 100%;
        }
      }
      .name {
        margin-left: 12px;
        flex: 0 0 50px;
      }
      .content {
        margin-left: 61px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .comment-status {
      margin-top: 25px;
      font-size: 14px;
      font-weight: 400;
      line-height: 13px;
      text-align: center;
      color: #999999;
    }
  }
}
</style>
