
import { computed, defineComponent, onMounted, reactive } from 'vue'
import { useReflectiveInjector, Type } from '@tanbo/vue-di-plugin'
import { scrollTo } from '@/utils/scroll'
import { InterestsService, InterestList, AppInterestList, InterestDescLists } from './interests.service'
import { ConfigService, getSoftwareName } from '@/services/config.service'
import { BridgeService } from '@/services/bridge.service'
import { TabService } from '@/views/home/_components/tab.service'
import { UserService } from '@/services/user.service'
import { CookieService } from '@/services/cookie.service';
import ProgressBar from './_components/progress-bar.vue'
import InstallModal from './_components/install-modal.vue'
import InterestDescList from './_components/interest-desc-list.vue'

/** 权益 */
export default defineComponent({
  name: 'Interests',
  components: { ProgressBar, InstallModal, InterestDescList },
  setup () {
    const injector = useReflectiveInjector()
    const service = injector.get(InterestsService)
    const config = injector.get(ConfigService as Type<ConfigService>)
    const bridge = injector.get(BridgeService)
    const user = injector.get(UserService)
    const cookie = injector.get(CookieService)
    const tab = injector.get(TabService)

    const viewModel = reactive({
      os: '',
      title: config.getTitle,
      isVip: user.isVip,
      isKeniuSuperMember: user.isKeniuSuperMember,
      pid: '', /** 产品id */
      cmd: '', /** 客户端吊起参数 */
      tabType: tab.updateSelectTab(), /** 当前tab类型 */
      modalVisible: false, /** 控制对话框显示隐藏 */
      interestList: [] as AppInterestList[], /** 普通权益列表 */
    })

    user.onUserInfoChanged.subscribe(() => {
      viewModel.isVip = user.isVip
      viewModel.isKeniuSuperMember = user.isKeniuSuperMember
    })

    /** 当前权益列表 */
    const currentInterestList = computed(() => {
      return viewModel.tabType === 'normal'
        ? viewModel.interestList.filter((_, i) => i < 1)
        : viewModel.interestList
    })

    /** 超级会员权益总数 */
    const interesTotal = computed(() => {
      let total = 0

      currentInterestList.value.forEach(e => {
        total += e.interest_list.length
      })

      return total
    })

    const interestTitle = computed(() => {
      const no_vip_text = `可牛超级会员享可牛软件所有会员权益(${interesTotal.value}项)`
      const vip_text = `您已享可牛软件所有会员权益(${interesTotal.value}项)`

      return viewModel.isKeniuSuperMember ? vip_text : no_vip_text
    })

    onMounted(() => {
      service.requestDeviceInfo().then(({ os }) => {
        viewModel.os = os
      }).finally(() => {
        initInterest()
      })
    })

    /** 订阅tab切换变化 */
    tab.onTabToggle.subscribe(type => {
      viewModel.tabType = type
    })

    /**
     * @description 初始化权益列表
     */
    function initInterest() {
      const params = { os: viewModel.os }

      service.requestInterest(params).then(res => {
        viewModel.interestList = res.app_interest_list
      })
    }

    /**
     * @description 处理点击权益调起
     */
    // eslint-disable-next-line complexity
    async function clickInterestItem (item: InterestList, productId: string) {
      // 当前权益所属产品id不等当前产品id时
      if (productId !== cookie.productId) {
        const vip_version = Number(cookie.vipVersion)
        const specialKantu = cookie.productId === '100' && vip_version >= 4 // 看图客户端版本特殊处理
        const softname = getSoftwareName[productId]

        if (specialKantu || vip_version >= 3) {
          // 检测当前app是否存在
          const { result } = await bridge.isAppInstall({ softname }) || {}
          if (!result) {
            controlModalVisible(true)
            viewModel.pid = productId
            viewModel.cmd = item.cmd
          } else {
            // 打开软件
            const params = {
              softname,
              cmd: item.cmd
            }
            
            bridge.awakenApp(params)
          }
        } else {
          window.open('https://www.keniu.com/')
        }
        return
      }

      if (viewModel.isVip || viewModel.tabType === 'super' || !item.desc_key) {
        if (!item.is_start_up) {
          return
        }

        item.progess_bar = true

        setTimeout(() => {
          item.progess_bar = false
        }, 2000)

        callClientTop(item)
        return
      }

      scrollToElement(item)
    }

    /**
     * @description 上方权益卡牌调用客户端
     */
    function callClientTop (item: InterestList) {
      const { path, cmd } = item

      bridge.openKisProcess({ path, cmd })
    }

    /**
     * @description 下方权益描述调用客户端
     */
    function callClientBottom (item: InterestDescLists) {
      const { path, cmd } = item?.page_detail

      bridge.openKisProcess({ path, cmd })
    }

    /**
     * @description 滚动至当前标识元素
     */
    function scrollToElement (item: InterestList) {
      const className = `scroll_${item.desc_key}`
      const _dom = document.querySelector(`.${className}`) as HTMLElement

      if (!_dom) {
        return
      }

      const scrollTop = _dom.offsetTop - 180
      const _parent = document.querySelector('.wrapper_scroll')
      scrollTo(scrollTop, 200, _parent)
    }

    /**
     * @description 对话框关闭处理
     */
    function modalCancel () {
      controlModalVisible(false)
    }

    /**
     * @description 对话框成功处理
     */
    function modalSuccess () {
      const softname = getSoftwareName[viewModel.pid]
      const params = {
        softname,
        cmd: viewModel.cmd
      }
      bridge.awakenApp(params)
      controlModalVisible(false)
    }

    /**
     * @description 控制对话框显示隐藏
     */
    function controlModalVisible(visible: boolean) {
      viewModel.modalVisible = visible
    }

    return {
      viewModel,
      interesTotal,
      interestTitle,
      callClientBottom,
      clickInterestItem,
      currentInterestList,
      modalCancel,
      modalSuccess,
    }
  }
})
