import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "super_interest_bg"
}
const _hoisted_2 = { class: "interests_title" }
const _hoisted_3 = { class: "bl_name" }
const _hoisted_4 = {
  key: 0,
  class: "ye_desc"
}
const _hoisted_5 = { class: "interests_list flex_start_center" }
const _hoisted_6 = {
  key: 0,
  class: "super_member"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_InterestDescList = _resolveComponent("InterestDescList")!
  const _component_InstallModal = _resolveComponent("InstallModal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.viewModel.tabType === 'super')
      ? (_openBlock(), _createBlock("div", _hoisted_1, _toDisplayString(_ctx.interestTitle), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.currentInterestList, (p, pIdx) => {
      return (_openBlock(), _createBlock("div", {
        class: "interests",
        key: pIdx
      }, [
        _createVNode("div", _hoisted_2, [
          _createVNode("span", _hoisted_3, _toDisplayString(p.name), 1),
          (_ctx.viewModel.tabType === 'super')
            ? (_openBlock(), _createBlock("span", _hoisted_4, "（" + _toDisplayString(p.interest_list.length || 0) + "项特权）", 1))
            : _createCommentVNode("", true)
        ]),
        _createVNode("ul", _hoisted_5, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(p.interest_list, (c, cIdx) => {
            return (_openBlock(), _createBlock("li", {
              key: cIdx,
              class: "interests_item flex_column_centers",
              onClick: ($event: any) => (_ctx.clickInterestItem(c, p.app_id))
            }, [
              (c?.page_detail?.is_super_member)
                ? (_openBlock(), _createBlock("i", _hoisted_6))
                : _createCommentVNode("", true),
              _createVNode("img", {
                src: c.img_url,
                alt: "",
                class: "common_interest_block"
              }, null, 8, ["src"]),
              _createVNode("span", null, _toDisplayString(c.title), 1),
              _withDirectives(_createVNode(_component_ProgressBar, null, null, 512), [
                [_vShow, c.progess_bar]
              ])
            ], 8, ["onClick"]))
          }), 128))
        ]),
        (_ctx.viewModel.tabType === 'normal')
          ? (_openBlock(), _createBlock(_component_InterestDescList, {
              key: 0,
              list: _ctx.currentInterestList[0].interest_desc_list
            }, {
              btn: _withCtx(({ item }) => [
                (item.page_detail?.btn_text)
                  ? (_openBlock(), _createBlock("div", {
                      key: 0,
                      class: "spread-btn",
                      onClick: ($event: any) => (_ctx.callClientBottom(item))
                    }, _toDisplayString(item.page_detail?.btn_text), 9, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["list"]))
          : _createCommentVNode("", true),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.viewModel.modalVisible)
              ? (_openBlock(), _createBlock(_component_InstallModal, {
                  key: 0,
                  pid: _ctx.viewModel.pid,
                  onOnSuccess: _ctx.modalSuccess,
                  onOnCancel: _ctx.modalCancel
                }, null, 8, ["pid", "onOnSuccess", "onOnCancel"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]))
    }), 128))
  ], 64))
}