
import { defineComponent, computed, reactive, onUnmounted } from 'vue'
import { useReflectiveInjector, Type } from '@tanbo/vue-di-plugin'
import { UserService } from '@/services/user.service'
import { BridgeService } from '@/services/bridge.service'
import { UserInfoService } from './user-info.service'
import { login_type_info, ConfigService } from '@/services/config.service'

import UserInfoDetail from '@/views/user-info/_components/user-info-detail.vue'
import { CommonService } from '@/services/common.service';
import { TabService } from '@/views/home/_components/tab.service';

/** 用户信息 */
export default defineComponent({
  name: 'UserInfo',
  components: { UserInfoDetail },
  props: {
    fixedHeight: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const injector = useReflectiveInjector()
    const service = injector.get(UserInfoService)
    const user = injector.get(UserService)
    const bridge = injector.get(BridgeService)
    const config = injector.get(ConfigService as Type<ConfigService>)
    const common = injector.get(CommonService)
    const tab = injector.get(TabService)

    const viewModel = reactive({
      detailVisible: false, /** 控制详情显示 */
      isVip: user.isVip,
      avatar: user.avatar,
      nickname: user.nickname,
      isLogin: user.isLogin,
      loignType: user.loignType,
      title: config.getTitle,
      isSingleNormalMember: user.isSingleNormalMember,
      isSingleDiamondMember: user.isSingleDiamondMember,
      isSingleMember: user.isSingleMember,
      isKeniuSuperMember: user.isKeniuSuperMember,
      singleMemberInfo: user.singleMemberInfo,
      keniuSuperMemberInfo: user.keniuSuperMemberInfo
    })

    /** 订阅用户信息变化 */
    const unUserInfoChanged = user.onUserInfoChanged.subscribe(() => {
      const assignmentField = [
        'isVip',
        'avatar',
        'isLogin',
        'nickname',
        'loignType',
        'isSingleNormalMember',
        'isSingleDiamondMember',
        'isSingleMember',
        'isKeniuSuperMember',
        'singleMemberInfo',
        'keniuSuperMemberInfo'
      ]

      assignmentField.forEach((field) => (viewModel[field] = user[field]))
    })

    onUnmounted(() => {
      unUserInfoChanged.unsubscribe()
    })

    /** 头部描述定制文案 */
    const headText = computed(() => {
      if (!viewModel.isLogin) { // 未登录
        return '登录获取最新优惠信息!'
      }

      if (!viewModel.isVip) { // 非会员
        return '升级为会员，享会员特权服务'
      }

      if (viewModel.isSingleNormalMember || viewModel.isSingleDiamondMember) {
        return `${viewModel.title}会员：${memberInterestDateText('single')}`
      }

      return ''
    })

    /** 登录类型class */
    const loginTypeClass = computed(() => {
      const loginTypeMap = {
        [login_type_info.wechat]: 'login_type_wechat',
        [login_type_info.qq]: 'login_type_qq',
        [login_type_info.phone]: 'login_type_phone'
      }

      return loginTypeMap[viewModel.loignType]
    })

    /** 点击续费按钮 */
    function clickRenew() {
      tab.onTabToggle.next('normal')
      service.onRenew.next()
    }

    /**
     * @description 处理会员日期文案
     */
    function memberInterestDateText (type: string) {
      const superTime = viewModel.keniuSuperMemberInfo?.expired_at
      const singleTime = viewModel.singleMemberInfo?.expired_at

      if (type === 'super' && viewModel.isKeniuSuperMember) {
        return viewModel.keniuSuperMemberInfo?.page_detail?.is_diamond ? '永久' : common.handleDate(Number(superTime))
      }

      if (type === 'single' && viewModel.isSingleMember) {
        return viewModel.singleMemberInfo?.page_detail?.is_diamond ? '永久' : common.handleDate(Number(singleTime))
      }

      return '未开通'
    }

    /** 点击登录 */
    function clickLogin() {
      bridge.userLogin()
    }

    /** 错误使用默认头像 */
    function errorImg() {
      viewModel.avatar = require('@/assets/images/not-login-avatar.png')
    }

    /**
     * @description 打开用户详情
     */
    function openUserDetail () {
      if (!viewModel.isLogin) {
        return
      }

      controlModalVisible(true)
    }

    /**
     * @description 对话框关闭处理
     */
    function modalCancel () {
      controlModalVisible(false)
    }

    /**
     * @description 查看权益
     */
    function seeInterest () {
      window.open(config.getWebsiteUrl)
    }

    /**
     * @description 对话框成功处理
     */
    function modalSuccess () {
      controlModalVisible(false)
    }

    /**
     * @description 控制对话框显示隐藏
     */
    function controlModalVisible(visible: boolean) {
      viewModel.detailVisible = visible
    }

    return {
      headText,
      viewModel,
      loginTypeClass,
      errorImg,
      clickRenew,
      clickLogin,
      modalCancel,
      seeInterest,
      modalSuccess,
      openUserDetail,
      memberInterestDateText
    }
  },
})
