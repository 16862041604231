
import { defineComponent, PropType } from 'vue';

import type { Payment, PAY_TYPE } from './types';

export default defineComponent({
  name: 'Payment',
  props: {
    payments: {
      type: Array as PropType<Payment[]>,
      default: () => []
    },
    current: {
      type: Object as PropType<Payment>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => { }
    },
    /** 限制使用的支付类型 */
    interdict: {
      type: Array as PropType<PAY_TYPE[]>,
      default: () => []
    }
  },
  emits: ['select'],
  setup(props, { emit }) {
    function handleChange(type: PAY_TYPE) {
      changePayment(type)
    }
    function changePayment(type: PAY_TYPE) {
      // @ts-ignored
      const selected = props?.payments?.find(item => item.type === type)
      emit('select', selected)
    }
    return { props, handleChange }
  }

});
