
import { defineComponent, reactive, PropType, ref, onMounted } from 'vue';

import { useReflectiveInjector } from '@tanbo/vue-di-plugin';
import { Coupon, CouponService } from './coupon.service';
import { PayPackages, PaySettingsService } from './pay-settings.service';

export default defineComponent({
  name: 'CouponList',
  props: {
    packageList: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    currentPackageMapId: {
      type: String,
      default: '',
    },
    isUseCoupon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const injector = useReflectiveInjector();
    const service = injector.get(PaySettingsService);
    const couponService = injector.get(CouponService);
    /** 当前选中是哪个套餐 */
    const activatedIndex = ref(-1);
    const viewModel = reactive({
      // 只有用户手动点击优惠券才算使用
      isUseCoupon: props.isUseCoupon,
    });
    const payPackages = ref<PayPackages[]>([]);
    const couponPayPackages = ref<PayPackages[]>([]);
    const couponList = ref<Coupon[]>();

    service.couponPayPackageGroup.subscribe((group) => {
      if (group) {
        couponPayPackages.value = group.payPackages;
      }
    });

    service.payPackageGroup.subscribe((group) => {
      if (group) {
        payPackages.value = group.payPackages;
      }
    });

    service.onCouponToggle.subscribe(selectedItem => {
      if (viewModel.isUseCoupon) {
        if (selectedItem === undefined) {
          viewModel.isUseCoupon = false
        } else {
          const index = couponList.value.findIndex(item => item.product_id === selectedItem.product_id)
          activatedIndex.value = index

          const current = couponList.value[index];
          const payPackage = couponPayPackages.value.find((item) => item.product_id === current.coupon_product_id)
          service.onPaySettingToggle.next(payPackage);
        }
      }
    })

    couponService.couponList.subscribe((list) => {
      if (list.length > 0) {
        couponList.value = list;
      }
    });

    function clickCoupon(index) {
      if (activatedIndex.value === index) {
        activatedIndex.value = -1;
        viewModel.isUseCoupon = false;
      } else {
        viewModel.isUseCoupon = true;
        activatedIndex.value = index;
      }
      handleCouponChange(index);
    }

    function handleCouponChange(index) {
      const current = couponList.value[index];
      const payPackage = viewModel.isUseCoupon
        ? couponPayPackages.value.find(
          (item) => item.product_id === current.coupon_product_id
        )
        : payPackages.value.find((item) => item.product_id === current.product_id);
      service.onPaySettingToggle.next(payPackage);
      service.onCouponToggle.next(current);
      if (viewModel.isUseCoupon) {
        couponService.reportCouponUse(Number(current.page_detail.discount))
      } else {
        couponService.reportCouponCancel(Number(current.page_detail.discount))
      }
    }

    onMounted(() => {
      couponService.reportCouponShow()
    })

    return {
      viewModel,
      activatedIndex,
      clickCoupon,
      payPackages,
      couponList,
    };
  },
});
