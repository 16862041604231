
/** 支付tab */
import { onUnmounted, reactive } from 'vue'
import { useReflectiveInjector, Type } from '@tanbo/vue-di-plugin'
import { ConfigService } from '@/services/config.service'
import { UserService } from '@/services/user.service'
import { TabService } from '@/views/home/_components/tab.service'

export default {
  name: 'PayTab',
  setup() {
    const injector = useReflectiveInjector()
    const service = injector.get(TabService)
    const config = injector.get(ConfigService as Type<ConfigService>)
    const user = injector.get(UserService)

    const viewModel = reactive({
      type: service.updateSelectTab(),
      tabs: [
        {
          label: `${config.getTitle}会员`,
          type: 'normal',
          isTag: false
        },
        {
          label: '可牛超级会员',
          type: 'super',
          isTag: true
        }
      ]
    })

    const subs = [
      /** 订阅用户信息变化 */
      user.onUserInfoChanged.subscribe(() => {
        const currentType = service.updateSelectTab()

        viewModel.type = currentType
        service.onTabToggle.next(currentType)
      }),
      service.onTabToggle.subscribe(type => {
        viewModel.type = type
      })
    ]

    onUnmounted(() => {
      subs.forEach(e => e.unsubscribe())
    })

    /**
     * @description 切换tab
     */
    function switchTab (type: string) {
      if (viewModel.type === type) {
        return
      }

      viewModel.type = type
      service.onTabToggle.next(type)
      service.updateCurrentTab(type)
    }

    return {
      viewModel,
      switchTab
    }
  }
}
