
import {defineComponent, reactive } from 'vue'
import { getTitleInfo, getInstallModalIcon } from '@/services/config.service'

/** 软件未安装拦截对话框 */
export default defineComponent({
  name: 'InstallModal',
  props: {
    pid: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const viewModel = reactive({
      title: '',
      modalIcon: '',
      pid: props.pid as string
    })

    const pid = viewModel.pid
    viewModel.title = getTitleInfo[pid]
    viewModel.modalIcon = getInstallModalIcon[pid]

    /**
     * @description 成功处理
     */
    function onSuccess () {
      emit('onSuccess')
    }

    /**
     * @description 取消处理
     */
    function onCancel () {
      emit('onCancel')
    }

    return {
      viewModel,
      onCancel,
      onSuccess
    }
  }
})
