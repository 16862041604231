import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/mc',
    name: 'MemberCenter',
    component: () => import('@/views/mobile/MemberCenter/index.vue')
  },
  {
    path: '/cmc',
    name: 'CouponMemberCenter',
    component: () => import('@/views/mobile/MemberCenter/CouponMemberCenter.vue')
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: () => import('@/views/exchange/index.vue')
  },
  {
    path: '/memberInterestDetail',
    name: 'memberInterestDetail',
    component: () => import('@/views/pure-display/member-interest-detail.vue')
  },
  {
    path: '/receiveMember',
    name: 'ReceiveMember',
    component: () => import(/* webpackChunkName: "ComputerReceiveMember" */ '@/views/custom-made/computer/receive-member.vue')
  },
  {
    path: '/koutu-editor',
    name: 'KoutueEditor',
    component: () => import(/* webpackChunkName: "KantuTemplateEditor" */ '@/views/custom-made/kantu/koutu-editor/koutu-editor.vue')
  },
  {
    path: '/koutu-member-center',
    name: 'KoutuMemberCenter',
    component: () => import(/* webpackChunkName: "KoutuMemberCenter" */ '@/views/custom-made/kantu/member-center/koutu-member-center.vue')
  },
  {
    path: '/pdf-convert',
    name: 'PdfConvert',
    component: () => import(/* webpackChunkName: "PdfConvert" */ '@/views/pdf-convert/pdf-convert.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
